import React from "react";
import { useSelector } from "react-redux";
import { Box, Card, CircularProgress } from "@mui/material";


const OverlayLoader = () => {
  const { overlayLoading } = useSelector((state) => state.master);
  return overlayLoading ? (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 20000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card>
        <Box sx={{display:'flex' ,p:2,mt:1}}>
          <Box sx={{pr:1}}> <CircularProgress size={23} /></Box>
          <Box>Loading...</Box>
        </Box>
      </Card>
      {/* <CircleLoader size={80} loading={overlayLoading} color="#faee27" /> */}
    </div>
  ) : null;
};
export default OverlayLoader;
