import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import StopIcon from "@mui/icons-material/Stop";
import WorkIcon from "@mui/icons-material/Work";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
  colors,
} from "@mui/material";

import Chip from "@mui/material/Chip";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import Timer from "../../components/customTimer/TimerMain";
import gif from "../../pics/clock.gif";
import resume from "../../pics/resume.png";
import {
  getAssignedTaskActionGet,
  getJobByProjectAction,
  getProjectCodeBYUserAction,
  getProjectCodeListAction,
  getUserTaskAction,
  giveCountAction,
  requestRevertAction,
} from "../../redux/action/action";
import {
  TASK_PAGE_ITEMS_PER_PAGE,
  TriggerAlert,
  removeDuplicatesFromArray1,
  secondsToHMS,
} from "../../utils/UIUtil";

import {
  AccountTree,
  BrowserUpdated,
  Cached,
  FiberManualRecord,
  SaveAs,
  Undo,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { green, grey, red } from "@mui/material/colors";
import EyeIcon from "../../components/commonComponents/eyeicon";
import GeneralTaskTimer from "../../components/generalTask/generalTaskTimer";
import SelectGeneralTask from "../../components/generalTask/selectTask";
import { conformPopup } from "../../components/popup/ConformPopup";
import { endGeneralTask } from "../../redux/reducers/commonReducer";
import { startGeneralTaskServiceAction } from "../../service/action/action";
import { formContainerCardStyle } from "../../utils/Style";
import {
  GENERALTASK_WARINING_MSG,
  MAINTASK_WARINING_MSG,
  TASKRESUME_WARINING_MSG,
  TASKSTART_WARINING_MSG,
} from "../../utils/textName";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { allpageFontStyle } from "../../utils/FontStyle";
import TaskPageCommen from "./TaskPageCommen";
import TaskHistoryListPage from "./TaskHistoryListPage";
// const useStyles = makeStyles((theme) => ({
//   dottedDivider: {
//     margin: theme.spacing(2, 0),
//     border: '1px dotted ' + theme.palette.primary.main, // customize color
//   },
// }));
export default function ShowTask({
  increment,
  handleStart,
  handlePause,
  handleReset,
  handleResume,
  jobCode1,
  setjobCode1,
}) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const [assignedTaskList, setAssigedTaskList] = useState([]);
  const listInnerRef = useRef();
  const apicall = useRef(false);
  const { assignedtaskData, pausetaskData, projectCodeDta, jobList } =
    useSelector((state) => state.master);
  const { timerData, userprofile, runingTask, autoLoad } = useSelector(
    (state) => state.presist
  );
  const { runningGeneralTask } = useSelector((state) => state.common);
  const [selectedStart, setselectedStart] = useState();
  const [selectedPause, setselectedPause] = useState();
  const [selectedResume, setselectedResume] = useState();
  const [selectedReset, setselectedReset] = useState();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [isopen, setIsOpen] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [selectedTask, setSelectedTask] = useState(null);
  const [remarks2, setRemarks2] = useState("");
  const [count, setCount] = useState("");
  const [constructData, setConstructData] = React.useState([]);
  const [keyValue, setkeyValue] = React.useState([]);
  const [payload, setpayload] = React.useState({
    jobcode: "",
    projectCode: "",
  });
  const [projectCode1, setprojectCode1] = useState("");
  const [revertIndex, setRevertIndex] = useState();

  const [selectedRow, setselectedRow] = useState({ type: "", index: "" });
  const { globapOrganization } = useSelector((state) => state.presist);
  const [itemPerPage, setItemPerPage] = useState(TASK_PAGE_ITEMS_PER_PAGE);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageInfo, setPageInfo] = useState(null);
  const [payload2, setPayload2] = useState({
    taskId: "",
    jobCode: "",
    completedCount: 0,
    remarks: "",
  });
  const [payload3, setPayload3] = useState({
    taskId: "",
    jobCode: "",
    completeCount: 0,
    status: "",
    state: "",
    workHours: 0,
    workMinutes: 0,
    workSeconds: 0,
  });

  // dispatch(updateMasterStates({ name: 'timerData', value: { dateValue: {}, startFun: '', pauseFun: '', resetFun: '', start: false, pause: false } }))
  const onScrollFunction = () => {
    dispatch(
      getAssignedTaskActionGet(
        itemPerPage,
        pageInfo?.currentPageNumber + 1,
        setPageInfo
      )
    );
  };
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight === scrollHeight) {
        if (pageInfo?.hasNext) {
          onScrollFunction();
        }
      }
    }
  };
  const constuctData = (arr) => {
    // const res = arr.filter((val) => val.jobTaskList?.taskList?.length > 0);
    const res2 = arr;
    //   res && res.length > 0 && res.map((val) => val.jobTaskList.taskList);
    // const constrcData=res&&res.length>0&&res.map((val)=>({

    // }))
    return res2;
  };
  useEffect(() => {
    if (assignedtaskData && assignedtaskData.length > 0) {
      // setAssigedTaskList([]);
      console.log(pausetaskData, "pausetaskData");
      const constuct = constuctData(assignedtaskData);
      const res = [...assignedTaskList, ...constuct];
      const uniqueArray = removeDuplicatesFromArray1(res);
      setAssigedTaskList(uniqueArray);

      const key = Object.keys(constuct?.map((datas) => datas?.taskInfo));
      setkeyValue(key);
    } else {
      setAssigedTaskList([]);
    }
  }, [assignedtaskData]);

  // Page Auto Reload By the Task Status changes

  useEffect(() => {
    if (autoLoad?.isAutoLoad) {
      if (payload?.jobcode) {
        const load = {
          jobCode: payload.jobcode ? payload.jobcode.jobCode : "",
        };
        setAssigedTaskList([]);
        dispatch(
          getUserTaskAction("post", load, "", itemPerPage, 1, setPageInfo)
        );
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: { isAutoLoad: false },
          })
        );
      } else {
        dispatch(getAssignedTaskActionGet(itemPerPage, 1, setPageInfo));
        dispatch(
          updatePresistStates({
            name: "autoLoad",
            value: { isAutoLoad: false },
          })
        );
      }
    }
  }, [autoLoad]);

  useEffect(() => {
    if (!apicall.current) {
      dispatch(getAssignedTaskActionGet(itemPerPage, pageNumber, setPageInfo));
      apicall.current = true;
    }
    dispatch(
      getProjectCodeBYUserAction(
        "post",
        `/report/projectInfo/${
          userprofile?.userAccess?.superUser === true
            ? globapOrganization?.orgId
            : userprofile?.userInfo?.userInfo?.orgId || ""
        }/`
      )
    );
  }, [globapOrganization]);
  // useEffect(()=>{
  //   if (assignedtaskData && assignedtaskData.length > 0) {
  //     const key = Object.keys(assignedtaskData?.map(datas=> datas?.taskInfo));
  //     setkeyValue(key)
  //   }

  // },[])

  const constructProjectData = (arr) => {
    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr.jobList,
      taskCategory: arr.taskCategory,
      taskType: arr.taskType,
      userInfo:
        arr.users &&
        arr.users.length > 0 &&
        arr.users.map((val) => val.userInfo),
    };
    return res;
  };
  React.useEffect(() => {
    if (jobList?.jobList?.length > 0) {
      const taskListData = constructProjectData(jobList);
      setConstructData(taskListData);
    }
  }, [jobList]);
  React.useEffect(() => {
    setSelectedTask(null);
    setRemarks("");
  }, []);
  const handelJopcode = (v) => {
    if (v) {
      setpayload({ ...payload, jobcode: v });
      setjobCode1(v);
    } else {
      setpayload({ ...payload, jobcode: v });
      setjobCode1(v);
    }
  };
  const constructTime = (H, M, S) => {
    const min = H * 60 + M + S / 60;
    return min;
  };

  function toSeconds(hours, minutes, seconds) {
    return hours * 3600 + minutes * 60 + seconds;
  }
  const handelStart = (i) => {
    // const res = assignedTaskList.some((val) => val.taskState === "Started");
    // const res1 = assignedTaskList.some((val) => val.taskState === "Resumed");
    // if (res || res1) {
    // setselectedRow({ type: "start", index: i });
    // setOpen(true);
    // } else {
    if (
      runningGeneralTask?.state !== "Started" &&
      runningGeneralTask?.state !== "Updated"
    ) {
      setselectedStart(i);
    } else {
      TriggerAlert(
        "warning",
        TASKSTART_WARINING_MSG(runningGeneralTask?.genTaskName)
      );
    }
    // }
  };
  const handelPause = (i, task) => {
    // setselectedPause(i);
    setOpen(task);
    setselectedResume("");
    setselectedRow({ type: "pause", index: i });
  };
  const handelResume = (i) => {
    // const res = assignedTaskList.some((val) => val.taskState === "Started");
    // const res1 = assignedTaskList.some((val) => val.taskState === "Resumed");
    // if (res || res1) {
    //   setselectedRow({ type: "resume", index: i });
    //   setOpen(true);
    // } else {
    if (
      runningGeneralTask?.state !== "Started" &&
      runningGeneralTask?.state !== "Updated"
    ) {
      setselectedResume(i);
      setselectedPause("");
    } else {
      TriggerAlert(
        "warning",
        TASKRESUME_WARINING_MSG(runningGeneralTask?.genTaskName)
      );
    }
    // }
  };
  const handelReset = (i) => {
    // setselectedReset(i);
    // setOpen(true);
    conformPopup("Confirm", "Are you sure you want to end this Task?", () => {
      setselectedReset(i);
      setselectedStart("");
    });
    // setselectedRow({ type: "end", index: i });
  };
  const handelChangedymanicField = (e, v) => {
    setRemarks(e);
    const res = assignedTaskList[v];
    // const res2 = { ...res };
    // res2.remarks = e;
    // const modifiedArray = [
    //   ...assignedTaskList.slice(0, v),
    //   res2,
    //   ...assignedTaskList.slice(v + 1),
    // ];
    setIsOpen(!isopen);
    // setAssigedTaskList(modifiedArray);
  };
  const handleRevert = (task, i) => {
    setOpen2(task);
    // setdummy(task);
    setRevertIndex(i);
    setPayload2({
      ...payload2,
      taskId: task?.taskId,
      jobCode: task?.jobCode,
    });
  };
  const handleCount = (task, i) => {
    setOpen3(task);
    // setdummy(task);
    // if (task.measureCount - task.completedCount !== 0) {

    setPayload3({
      ...payload3,
      taskId: task?.taskId,
      jobCode: task?.jobCode,
      state: task?.state,
      status: task.status,
      workHours: task?.workHours,
      workMinutes: task?.workMinutes,
      workSeconds: task?.workSeconds,
      completeCount: task?.completedCount,
    });
  };
  // setPayload3({...payload3, completeCount:task?.completedCount})
  // };
  // console.log(open3, "open3")
  const revertSubmit = async () => {
    //  await setPayload2({...payload2, completedCount:count, remarks:remarks2})
    const res = await dispatch(requestRevertAction(payload2));
    if (res) {
      dispatch(getAssignedTaskActionGet(itemPerPage, pageNumber, setPageInfo));
      // const res1 = [...assignedTaskList]
      // res1.splice(revertIndex, 1, res?.data.data[0])
      // setAssigedTaskList(res1)
    }
    handleClose2();
  };
  const countSubmit = async () => {
    // console.log('getcount')
    // setPayload3({...payload3, completeCount: })
    const res = await dispatch(giveCountAction(payload3));
    if (res) {
      dispatch(getAssignedTaskActionGet(itemPerPage, pageNumber, setPageInfo));
      handleClose3();
      setOpen3(false);
    }
  };
  const handleClose = () => {
    setRemarks("");
    setSelectedTask(null);
    setOpen(false);
  };
  const handleClose2 = () => {
    // setRemarks("");
    setOpen2(false);
    setCount("");
    setRemarks2("");
    setPayload2({
      taskId: "",
      jobCode: "",
      completedCount: 0,
      remarks: "",
    });
  };
  const handleClose3 = () => {
    // setRemarks("");
    setOpen3(false);

    setPayload3({
      taskId: "",
      jobCode: "",
      completeCount: 0,
      status: "",
      state: "",
      workHours: 0,
      workMinutes: 0,
      workSeconds: 0,
    });
  };
  const handelProjectCode = (e) => {
    if (e) {
      setpayload({ ...payload, projectCode: e?.projectCode });
      setprojectCode1(e);
      setjobCode1("");
      setpayload({ ...payload, jobcode: "" });
      dispatch(
        getJobByProjectAction({
          projectCode: e?.projectCode,
          orgId:
            userprofile?.userAccess?.superUser === true
              ? globapOrganization?.orgId
              : userprofile?.userInfo?.userInfo?.orgId,
        })
      );
    } else {
      setpayload({ ...payload, projectCode: e });
      setprojectCode1(e);
      setjobCode1("");
      setConstructData([]);
    }
  };
  const handelSave = () => {
    // setRemarks("");
    if (selectedRow.type === "pause") {
      setselectedPause(selectedRow.index);
      setOpen(false);
    }
    if (selectedRow.type === "end") {
      setselectedReset(selectedRow.index);
      setOpen(false);
    }
    if (selectedRow.type === "start") {
      setselectedStart(selectedRow.index);
      setOpen(false);
    }
    if (selectedRow.type === "resume") {
      setselectedResume(selectedRow.index);
      setOpen(false);
    }
  };
  const assign = async (refreshId) => {
    if (jobCode1) {
      const load = {
        // projectCode: payload.projectCode ? payload.projectCode : "",
        jobCode: payload.jobcode ? payload.jobcode.jobCode : "",
      };
      setAssigedTaskList([]);
      await dispatch(
        getUserTaskAction(
          "post",

          load,
          refreshId,
          itemPerPage,
          pageNumber,
          setPageInfo
        )
      );
      // setSelectionRow([]);
      // setSelectedFile([]);
    } else {
      TriggerAlert("warning", "Select Jobcode");
    }
  };
  const onSelectGeneralTask = (task) => {
    if (runingTask?.state === "Started" || runingTask?.state === "Resumed") {
      if (runingTask?.state === "Started" || runingTask?.state === "Resumed") {
        TriggerAlert(
          "warning",
          MAINTASK_WARINING_MSG(runingTask?.taskId, task?.genTaskName)
        );
      } else {
        conformPopup(
          "Confirm",
          `Do you want to start this ${task?.genTaskName} task ? (Current task will be paused)`,
          () => {
            startGeneralTaskServiceAction(task);
            setSelectedTask(task);
            setselectedPause(runingTask);
          }
        );
      }
    } else if (task) {
      if (
        runningGeneralTask?.state !== "Started" &&
        runningGeneralTask?.state !== "Updated"
      ) {
        conformPopup(
          "Confirm",
          `Do you want to start ${task?.genTaskName} task ?`,
          () => {
            runningGeneralTask
              ? dispatch(
                  endGeneralTask({
                    task: runningGeneralTask,
                    upComingTask: task,
                  })
                )
              : startGeneralTaskServiceAction(task);
            setSelectedTask(task);
          }
        );
      } else {
        TriggerAlert(
          "warning",
          GENERALTASK_WARINING_MSG(
            runningGeneralTask?.genTaskName,
            task?.genTaskName
          )
        );
      }
    }
    setSelectedTask(null);
  };
  const heading = {
    ...allpageFontStyle.typography1,
    color: "grey",
    display: "flex",
    justifyContent: "start",
    width: "40%",
    flexWrap: "wrap",
    maxWidth: "30%",
    textOverflow: "hidden",
    alignItems: "center",
  };
  const content = {
    ...allpageFontStyle.typography1,
    fontWeight: "bold",
    pl: 2,
    display: "flex",
    justifyContent: "start",
    width: "60%",
  };
  return (
    <Box sx={{ flex: 1, background: grey[100], px: 3, pt: 1, pl: 1 }}>
      <Card
        sx={{
          p: 5,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: 0,
          overflow: "hidden",
          ...formContainerCardStyle,
        }}
      >
        <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
          <Box sx={{ width: "30%", mx: 1 }}>
            <SelectComponent
              DataOptions={projectCodeDta?.project_details}
              labelName={(option) => option.projectCode}
              value={projectCode1}
              label={"Project Code"}
              handelChange={handelProjectCode}
            />
          </Box>

          <Box sx={{ width: "30%", mx: 1 }}>
            <SelectComponent
              DataOptions={constructData.jobList}
              labelName={(option) => option.jobCode}
              value={jobCode1}
              label={"Job Code"}
              handelChange={handelJopcode}
            />
          </Box>

          <Button
            size="small"
            sx={{
              borderRadius: 5,
              ...allpageFontStyle.buttonFontStyle2,
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
            }}
            onClick={() => assign()}
            disabled={!projectCode1?.projectCode || !jobCode1?.jobCode}
            variant="contained"
          >
            Load
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            mt: 2,
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "30%", mx: 1 }}>
            <SelectGeneralTask
              selectedTask={selectedTask}
              runningGeneralTask={runningGeneralTask}
              setSelectedTask={onSelectGeneralTask}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
            // bottom: 0,
            flex: 1,
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: "white",
            pt: "2.5rem",
            mt: "1rem",
            maxHeight: 300,
            overflowY: "auto",
            width: "100%",
          }}
        >
          {pausetaskData &&
            pausetaskData.length > 0 &&
            pausetaskData.map((task, index) => (
              <TaskHistoryListPage
                increment={increment}
                style={{
                  p: "0.5rem",
                  backgroundColor: "#eceef2",
                  borderRadius: "0.4rem",
                }}
                mapData={true}
                mapval={task}
                handleStart={handleStart}
                handlePause={handlePause}
                handleReset={handleReset}
                handleResume={handleResume}
                timerData={timerData}
                jobCode1={jobCode1}
              />
            ))}
          {console.log(runingTask, "runninggggg")}
          {runingTask &&
          (runingTask?.state === "Resumed" ||
            runingTask?.state === "Started") ? (
            <TaskPageCommen
              increment={increment}
              style={{
                p: "0.5rem",
                backgroundColor: "#eceef2",
                borderRadius: "0.4rem",
              }}
              page={"task"}
              handleStart={handleStart}
              handlePause={handlePause}
              handleReset={handleReset}
              handleResume={handleResume}
              timerData={timerData}
              jobCode1={jobCode1}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            width: "100%",
            minWidth: "100%",
            overflowX: "hidden",
          }}
          ref={listInnerRef}
          onScroll={onScroll}
        >
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Grid container spacing={3} sx={{ my: 2 }}>
              <GeneralTaskTimer
                isCardStyle={true}
                setSelectedTask={setSelectedTask}
              />
              {assignedTaskList &&
                assignedTaskList.length > 0 &&
                assignedTaskList.map((task, index) => (
                  <Grid item xs={6} md={6} lg={4} sx={{ p: 1 }}>
                    <Card
                      sx={{
                        // mx:2,

                        borderRadius: 2,

                        // p: 1,
                        opacity: task.status === "Revert Requested" ? 0.7 : 1,
                        backgroundColor:
                          ((task.state === "Started" ||
                            task.state === "Resumed") &&
                            timerData?.timestate >= +task.productionHours) ||
                          toSeconds(
                            task.workHours,
                            task.workMinutes,
                            task.workSeconds
                          ) >= +task.productionHours
                            ? red[100]
                            : task.state === "Started" ||
                              task.state === "Resumed"
                            ? green[50]
                            : "white",
                        width: "100%",
                        border:
                          task.state === "Started" || task.state === "Resumed"
                            ? "2px solid green"
                            : "1px solid grey",
                        boxShadow: "none",
                        position: "relative",
                        ":hover": {
                          boxShadow:
                            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: 280,
                          minHeight: 280,
                          overflow: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            p: 1,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "start",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  ...allpageFontStyle.title,
                                  mr: 1,
                                  color: "#358c4e",
                                  alignItems: "center",
                                }}
                              >
                                {" "}
                                {task?.taskId} ({task.taskType})
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {task.status === "Revert Requested" ? (
                                  <Chip
                                    sx={{ borderColor: "red" }}
                                    variant="outlined"
                                    label={
                                      <Box
                                        sx={{
                                          color: "red",
                                          // border: '1px solid red',

                                          ...allpageFontStyle.buttonFontStyle2,
                                          display: "flex",
                                          alignItems: "center",
                                          borderRadius: 3,
                                          justifyContent: "center",
                                        }}
                                      >
                                        <PriorityHighIcon
                                          sx={{ color: "#fa5252" }}
                                        />
                                        Requested to revert
                                      </Box>
                                    }
                                  />
                                ) : null}
                              </Box>
                              {task?.state !== "Assigned" &&
                              task?.state !== "Revert" ? (
                                <Tooltip
                                  title="Update Completed Count"
                                  placement="bottom"
                                >
                                  <SaveAs
                                    onClick={() => handleCount(task, index)}
                                    sx={{
                                      color: "white",
                                      cursor: "pointer",
                                      // background: "#e8cbf5",
                                      background: "#3163be",
                                      borderRadius: 2,
                                      p: 0.5,
                                      boxShadow:
                                        " rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                                      height: 30,
                                      width: 30,
                                    }}
                                  />
                                </Tooltip>
                              ) : null}
                            </Box>
                            <Grid container spacing={0}>
                              <Grid
                                item
                                xs={4}
                                md={4}
                                lg={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <AccountTree
                                  sx={{
                                    color: "#4287f5",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    color: "grey",
                                  }}
                                >
                                  Project Name
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={8}
                                lg={8}
                                sx={{ display: "flex" }}
                              >
                                <Box sx={{ fontSize: 12, fontWeight: "bold" }}>
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    ml: 1,
                                  }}
                                >
                                  {task.projectName}
                                </Box>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                md={4}
                                lg={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <WorkIcon
                                  sx={{
                                    color: "#C48F65",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    color: "grey",
                                  }}
                                >
                                  Job Code
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={8}
                                lg={8}
                                sx={{ display: "flex" }}
                              >
                                <Box sx={{ fontSize: 12, fontWeight: "bold" }}>
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    ml: 1,
                                  }}
                                >
                                  {task.jobCode}
                                </Box>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                md={4}
                                lg={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <MenuBookIcon
                                  sx={{
                                    color: "#1A4876",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    color: "grey",
                                  }}
                                >
                                  (Completed / Total) {task?.unitType}
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={8}
                                lg={8}
                                sx={{ display: "flex" }}
                              >
                                <Box sx={{ fontSize: 12, fontWeight: "bold" }}>
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    ml: 1,
                                  }}
                                >
                                  {task?.completedCount} / {task?.measureCount}
                                </Box>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                md={4}
                                lg={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <AccessTimeIcon
                                  sx={{
                                    color: "#087f5b",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    color: "grey",
                                  }}
                                >
                                  Est Hours
                                </Box>
                              </Grid>
                              <Grid
                                item
                                xs={8}
                                md={8}
                                lg={8}
                                sx={{ display: "flex" }}
                              >
                                <Box sx={{ fontSize: 12, fontWeight: "bold" }}>
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    ml: 1,
                                  }}
                                >
                                  {secondsToHMS(+task?.productionHours)}
                                </Box>
                              </Grid>

                              <Grid
                                item
                                xs={4}
                                md={4}
                                lg={4}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <EditIcon
                                  sx={{
                                    color: "#E3256B",
                                    fontSize: 14,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    color: "grey",
                                  }}
                                >
                                  Remarks
                                </Box>
                              </Grid>

                              <Grid
                                item
                                xs={8}
                                md={8}
                                lg={8}
                                sx={{ display: "flex" }}
                              >
                                <Box sx={{ fontSize: 12, fontWeight: "bold" }}>
                                  :
                                </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    ml: 1,
                                    display: "flex",
                                  }}
                                >
                                  {task.remarks}
                                </Box>
                              </Grid>
                            </Grid>

                            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                              <AccountTree
                                sx={{
                                  color: "#4287f5",
                                  fontSize: 14,
                                  display: "flex",
                                  alignItems: "center",
                                  mr: 1,
                                }}
                              />
                              <Box sx={{ ...allpageFontStyle.typography1, color: 'grey' }}>Project Name : </Box>
                              <Box sx={{ ...allpageFontStyle.typography1, fontWeight: "bold", ml: 1 }}>
                                {" "}{task.projectName}
                              </Box>
                            </Box> */}

                            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                              <WorkIcon
                                sx={{
                                  color: "#C48F65",
                                  fontSize: 14,
                                  display: "flex",
                                  alignItems: "center",
                                  mr: 1,
                                }}
                              />
                              <Box sx={{ ...allpageFontStyle.typography1, color: 'grey' }}>Job Code : </Box>
                              <Box sx={{ ...allpageFontStyle.typography1, fontWeight: "bold", ml: 1 }}>
                                {" "}{task.jobCode}
                              </Box>
                            </Box> */}

                            {/* <Box sx={{ display: "flex" }}>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                              
                                <MenuBookIcon
                                  sx={{
                                    color: "#1A4876",
                                    fontSize: 16,
                                    display: "flex",
                                    alignItems: "center",
                                    mr: 1,
                                  }}
                                />
                                <Box sx={{ ...allpageFontStyle.typography1, color: 'grey' }}>Total {task?.unitType} :</Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    ml: 1
                                  }}
                                >
                                  {task?.measureCount}
                                </Box>
                                <Box sx={{ ...allpageFontStyle.typography1, color: 'grey', ml: 2 }}>Completed {task?.unitType}: </Box>
                                <Box
                                  sx={{
                                    ...allpageFontStyle.typography1,
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    ml: 1
                                  }}
                                >
                                  {task?.completedCount}
                                </Box>
                              </Box>
                             

                            </Box> */}
                            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                              <AccessTimeIcon
                                sx={{
                                  color: "#087f5b",
                                  fontSize: 16,
                                  display: "flex",
                                  alignItems: "center",
                                  mr: 1,
                                }}
                              />
                              <Box sx={{ ...allpageFontStyle.typography1, color: 'grey' }}>Est Hrs : </Box>
                              <Box
                                sx={{
                                  ...allpageFontStyle.typography1,
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1
                                }}
                              >
                                {secondsToHMS(+task?.productionHours)}
                              </Box>
                            </Box> */}
                            {/* <Box sx={{ display: "flex" }}>
                              <EditIcon
                                sx={{
                                  color: "#E3256B",
                                  fontSize: 16,
                                  display: "flex",
                                  alignItems: "center",
                                  mr: 1,
                                }}
                              />
                              <Box sx={{ ...allpageFontStyle.typography1, color: 'grey' }}>Remarks: </Box>
                              <Typography
                                sx={{
                                  ...allpageFontStyle.typography1,
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  ml: 1, flexWrap: 'wrap'
                                }}
                              >
                                {task.remarks}
                              </Typography>
                            </Box> */}
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              ((task.state === "Started" ||
                                task.state === "Resumed") &&
                                timerData?.timestate >=
                                  +task.productionHours) ||
                              toSeconds(
                                task.workHours,
                                task.workMinutes,
                                task.workSeconds
                              ) >= +task.productionHours
                                ? red[100]
                                : task.state === "Started" ||
                                  task.state === "Resumed"
                                ? green[50]
                                : "white",

                            display: "flex",
                            flexDirection: "column",
                            // height: 140,
                            // minHeight: 140,
                            // overflow: "auto",
                            p: 1,
                            mt: 0,
                            pb: { sm: 5, md: 3, lg: 3 },
                            // background:'pink',
                          }}
                        >
                          {/* <Box
                          sx={{
                            fontSize: 14,
                            fontWeight: "bold",
                            width: "100%",
                            display: "flex",
                            justifyContent: "start",
                            textDecoration: 'underline',
                            pb: 0,
                          }}
                        >
                          Entities
                        </Box> */}
                          <Divider />
                          {Object.keys(task?.taskInfo).map((keyname) => (
                            <>
                              {keyname !== "Page Count" &&
                              keyname !== "category" ? (
                                <>
                                  {/* <Box
                                    sx={{
                                      display: "flex",
                                      width: "100%",
                                      py: 0.5,
                                      pb: 0,

                                      // minHeight:300

                                    }}
                                  >
                                    <Box
                                      sx={{ ...heading, wordBreak: "break-word" }}
                                    >
                                     <FiberManualRecord fontSize="inherit" sx={{color:'black', mr:1}}/>{keyname} dfg sdgsdhg fhfddddddd fhgfdhdf fhfdh
                                    </Box>
                                    <Box
                                      sx={{ ...content, wordBreak: "break-word" }}
                                    >
                                     <Box sx={{fontWeight:'bold', mr:1}}>:</Box> {task?.taskInfo[keyname]}
                                    </Box>
                                  </Box> */}

                                  <Grid container spacing={0}>
                                    <Grid
                                      item
                                      xs={4}
                                      md={4}
                                      lg={4}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FiberManualRecord
                                        sx={{
                                          color: "black",
                                          fontSize: 14,
                                          display: "flex",
                                          alignItems: "center",
                                          mr: 1,
                                        }}
                                      />
                                      <Box
                                        sx={{
                                          ...allpageFontStyle.typography1,
                                          color: "grey",
                                        }}
                                      >
                                        {keyname}
                                      </Box>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={8}
                                      md={8}
                                      lg={8}
                                      sx={{ display: "flex" }}
                                    >
                                      <Box
                                        sx={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        :
                                      </Box>
                                      <Box
                                        sx={{
                                          ...allpageFontStyle.typography1,
                                          fontWeight: "bold",
                                          ml: 1,
                                        }}
                                      >
                                        {task?.taskInfo[keyname]}
                                      </Box>
                                    </Grid>
                                  </Grid>

                                  <Divider />
                                </>
                              ) : // <Box sx={{ fontSize: 14, display: "flex" }}>
                              //   <Box sx={{ pr: 1, fontWeight: "bold" }}>
                              //     {keyname}:
                              //   </Box>{" "}
                              //   <Box>{task?.taskInfo[keyname] }</Box>
                              // </Box>
                              null}
                            </>
                          ))}
                          <Box sx={{ height: 20, visibility: "hidden" }}>
                            dgfdgdf
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          width: "100%",
                          position: "absolute",
                          height: 30,
                          minHeight: 30,
                          bottom: 0,
                          right: 0,
                          backgroundColor:
                            ((task.state === "Started" ||
                              task.state === "Resumed") &&
                              timerData?.timestate >= +task.productionHours) ||
                            toSeconds(
                              task.workHours,
                              task.workMinutes,
                              task.workSeconds
                            ) >= +task.productionHours
                              ? "black"
                              : task.state === "Started" ||
                                task.state === "Resumed"
                              ? "black"
                              : "black",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Stack
                          direction="row"
                          marginLeft={0}
                          alignItems={"center"}
                          width={"100%"}
                          justifyContent={"space-between"}
                          minWidth={"100%"}
                          paddingLeft={1}
                          // marginRight={5}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {/* <Box> {secondsToHMS(+task?.productionHours)}</Box>
                            <Box sx={{fontWeight:'bold', px:3}}>||</Box> */}
                            {task.state === "Started" ||
                            task.state === "Resumed" ? (
                              <img
                                src={gif}
                                alt=""
                                style={{ height: 30, width: 30 }}
                              />
                            ) : null}
                            <Timer
                              initialState={constructTime(
                                Number(task.workHours),
                                Number(task.workMinutes),
                                Number(task.workSeconds)
                              )}
                              selectedPause={selectedPause}
                              selectedReset={selectedReset}
                              selectedResume={selectedResume}
                              selectedStart={selectedStart}
                              setselectedPause={setselectedPause}
                              setselectedReset={setselectedReset}
                              setselectedResume={setselectedResume}
                              setselectedStart={setselectedStart}
                              indexValue={index}
                              taskValue={task}
                              increment={increment}
                              handleStart={handleStart}
                              handlePause={handlePause}
                              handleReset={handleReset}
                              handleResume={handleResume}
                              pageNumber={pageNumber}
                              setPageInfo={setPageInfo}
                              setAssigedTaskList={setAssigedTaskList}
                              refreshFunc={assign}
                              jobCode1={jobCode1}
                              remarks={remarks}
                              selectedtask={selectedTask}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {((task.state === "Started" ||
                              task.state === "Resumed") &&
                              timerData?.timestate >= +task.productionHours) ||
                            toSeconds(
                              task.workHours,
                              task.workMinutes,
                              task.workSeconds
                            ) >= +task.productionHours ? (
                              <Box
                                sx={{
                                  fontSize: 13,
                                  display: "flex",
                                  alignItems: "center",
                                  color: "red",
                                }}
                              >
                                <PriorityHighIcon sx={{ color: "#fa5252" }} />
                                <Box sx={{ color: "#fa5252" }}>
                                  Time Exceeded
                                </Box>{" "}
                              </Box>
                            ) : null}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Tooltip title="Start" placement="bottom">
                              <Button
                                clickable={true}
                                onClick={() => {
                                  handelStart(index);
                                }}
                                sx={{
                                  px: 0,
                                  py: 0,
                                  height: 30,
                                  minHeight: 30,
                                  minWidth: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  // borderStyle: "solid",
                                  // borderWidth: 0.5,
                                  // borderColor: "#656965",
                                  // backgroundColor: colors.green[300],
                                  display:
                                    task?.status === "In-Progress"
                                      ? "none"
                                      : task.state === "Revert"
                                      ? "none"
                                      : null,
                                  // p: 2,
                                  // ":hover": {
                                  //   backgroundColor: colors.green[600],
                                  // },
                                }}
                                disabled={task.state === "Revert"}
                                // disabled={
                                //   task.state === "Not Started" || task.state === ""
                                //     ? false
                                //     : true
                                // }
                                // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PlayCircleOutlineIcon sx={{ mr: 0.3 }} /><Box >START</Box></Box>}
                                variant="filled"
                              >
                                <PlayArrowIcon
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    height: 30,
                                    width: 30,
                                    color: colors.green[300],
                                  }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title="Pause" placement="bottom">
                              <Button
                                onClick={() => {
                                  handelPause(index, task);
                                }}
                                // disabled={
                                //   task.state === "Resumed" || task.state === "Started"
                                //     ? false
                                //     : true
                                // }
                                sx={{
                                  // backgroundColor: colors.deepOrange[300],

                                  px: 0,
                                  py: 0,
                                  height: 30,
                                  minHeight: 30,
                                  minWidth: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  // borderStyle: "solid",
                                  // borderWidth: 0.5,
                                  // borderColor: "#656965",
                                  display:
                                    task.state === "Resumed" ||
                                    task.state === "Started"
                                      ? null
                                      : task.state === "Revert"
                                      ? "none"
                                      : "none",
                                  // ":hover": {
                                  //   backgroundColor: colors.deepOrange[600],
                                  // },
                                }}
                                variant="filled"
                                // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PauseCircleOutlineIcon sx={{ mr: 0.3 }} /><Box >PAUSE</Box></Box>}
                              >
                                <PauseCircleOutlineIcon
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    height: 30,
                                    width: 30,
                                    color: colors.deepOrange[300],
                                  }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title="Resume" placement="bottom">
                              <Button
                                onClick={() => {
                                  handelResume(index, task);
                                }}
                                // disabled={task.state === "Paused" ? false : true}
                                sx={{
                                  // backgroundColor: colors.orange[300],

                                  display:
                                    task.state === "Paused"
                                      ? null
                                      : task.state === "Revert"
                                      ? "none"
                                      : "none",
                                  px: 0,
                                  py: 0,
                                  height: 30,
                                  minHeight: 30,
                                  minWidth: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  // borderStyle: "solid",
                                  // borderWidth: 0.5,
                                  // borderColor: "#656965",
                                  // ":hover": {
                                  //   backgroundColor: colors.orange[300],
                                  // },
                                }}
                                variant="filled"
                                // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><PlayArrowIcon sx={{}} /><Box >RESUME</Box></Box>}
                              >
                                {/* <PlayArrowIcon
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    height: 30,
                                    width: 30,
                                    color: colors.orange[300],
                                  }}
                                /> */}
                                <img
                                  src={resume}
                                  alt=""
                                  style={{ height: 17, width: 17 }}
                                />
                              </Button>
                            </Tooltip>

                            <Tooltip title="End" placement="bottom">
                              <Button
                                onClick={() => {
                                  handelReset(index, task);
                                }}
                                disabled={
                                  task.state === "Started" ||
                                  task.state === "Resumed"
                                    ? false
                                    : true
                                }
                                sx={{
                                  ml: 1,
                                  // backgroundColor: colors.red[300],
                                  px: 0,
                                  py: 0,
                                  height: 30,
                                  minHeight: 30,
                                  minWidth: 40,
                                  width: 40,
                                  borderRadius: "50%",
                                  // borderStyle: "solid",
                                  // borderWidth: 0.5,
                                  // borderColor: "#656965",
                                  display:
                                    task.state === "Revert"
                                      ? "none"
                                      : task.state === "Paused"
                                      ? "none"
                                      : task.state === "Not Started"
                                      ? "none"
                                      : "flex",
                                  // ":hover": {
                                  // backgroundColor: colors.red[400],
                                  // },
                                }}
                                variant="filled"
                                // label={<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><StopIcon sx={{}} /><Box >END</Box></Box>}
                              >
                                <StopIcon
                                  sx={{
                                    p: 0,
                                    m: 0,
                                    height: 30,
                                    width: 30,
                                    color: colors.red[300],
                                  }}
                                />
                              </Button>
                            </Tooltip>
                            {/* </Box> */}
                            <Tooltip title="Revert" placement="bottom">
                              <Button
                                onClick={() => {
                                  handleRevert(task, index);
                                }}
                                disabled={task.state !== "Paused"}
                                sx={{
                                  // backgroundColor: colors.blue[300],
                                  // ...cardbuttons,
                                  // borderStyle: "solid",
                                  // borderWidth: 0.5,
                                  // borderColor: "#656965",
                                  // px: 2,
                                  m: 0,
                                  mx: 1,
                                  height: 30,
                                  minHeight: 30,
                                  minWidth: 30,
                                  width: 30,
                                  borderRadius: "50%",
                                  display:
                                    task?.status === "In-Progress"
                                      ? null
                                      : task.state === "Revert"
                                      ? "none"
                                      : "none",
                                  // ":hover": { backgroundColor: colors.blue[400] },
                                }}
                                variant="text"
                              >
                                <Undo
                                  sx={{
                                    color: colors.blue[300],
                                    height: 25,
                                    width: 25,
                                  }}
                                />
                              </Button>
                            </Tooltip>
                            {/* <Tooltip  title="Preview" placement="bottom"> */}
                            <Box title={"Preview"} sx={{ p: 0 }}>
                              <EyeIcon
                                taskId={task.taskId}
                                jobcode={task.jobCode}
                                clickOpen={true}
                                clickclose={false}
                                icon={
                                  <MoreVertIcon
                                    sx={{
                                      color: "white",
                                      height: 25,
                                      width: 25,
                                      p: 0,
                                    }}
                                  />
                                }
                              />
                            </Box>
                            {/* </Tooltip> */}
                            {/* <Tooltip title="Preview" placement="bottom">
                            <Button
                            sx={{ px: 0,
                              py: 0,
                              height: 40,
                              minHeight: 40,
                              minWidth: 40,
                              width: 40,
                              borderRadius: "50%",}}
                              onClick={() => {
                                // dispatch(
                                //   updateMasterStates({
                                //     name: "taskDetails",
                                //     value: { ...task, indexId: index },
                                //   })
                                // );

                                // navigate(`/taskHistory/${task.taskId}`);
                              }}
                            >
                              {/* <img
                                alt=""
                                src={icon}
                                style={{
                                  height: 25,
                                  width: 25,
                                  borderRadius: "50%",
                                  p:0,
                                  m:0
                                 
                                }}
                              /> */}
                            {/* <PreviewIcon sx={{height:30, width:30, color:'#862e9c'}}/> */}
                            {/* </Button>
                          </Tooltip> */}
                          </Box>
                        </Stack>
                      </Box>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>

          <Dialog open={Boolean(open)} onClose={() => setSelectedTask(null)}>
            <DialogTitle
              sx={{
                display: "flex",
                justifyContent: "center",
                ...allpageFontStyle.title,
              }}
            >
              {" "}
              {open?.taskId}
            </DialogTitle>

            <DialogContent>
              {selectedRow.type === "end" || selectedRow.type === "pause" ? (
                <>
                  <Box sx={{ p: 1 }}>
                    {/* <Box sx={{ fontSize: 11, mb: 1 }}>Mandatory*</Box> */}

                    <TextField
                      variant="outlined"
                      inputProps={{ maxLength: 100 }}
                      type="text"
                      required
                      // value={assignedTaskList[index]?.remarks}

                      onChange={(e) => {
                        handelChangedymanicField(
                          e.target.value,

                          selectedRow.index
                        );
                      }}
                      label={"Remarks"}
                    />
                  </Box>
                </>
              ) : (
                <Box>
                  You already have Started task Or Resumed Task So please Pause
                  the task Then continue the anthor task
                </Box>
              )}
            </DialogContent>

            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="small"
                color="success"
                variant="contained"
                onClick={handelSave}
                autoFocus
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,
                  // p: 0,
                  // px: 2,
                  ...allpageFontStyle.buttonFontStyle2,
                  backgroundColor: "#258d4d",
                  ":hover": { backgroundColor: "green" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                disabled={remarks.length === 0}
              >
                Submit
              </Button>

              <Button
                size="small"
                color="error"
                variant="contained"
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,

                  ...allpageFontStyle.buttonFontStyle2,
                  // backgroundColor: "#bf6373",
                  // ":hover": { backgroundColor: "red" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={Boolean(open2)}>
            <DialogTitle sx={{ ...allpageFontStyle.title }}>
              Fill the following to revert the {open2?.taskId}
            </DialogTitle>

            <DialogContent>
              <Box sx={{ p: 1 }}>
                {/* <Box sx={{ fontSize: 11, mb: 1 }}>Mandatory*</Box> */}

                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                  // type="number"
                  required
                  value={payload2.completedCount}
                  // value={assignedTaskList[index]?.remarks}
                  onBlur={() =>
                    console.log(payload2.completedCount, "uyuiyuqq")
                  }
                  onChange={(e) => {
                    if (
                      (+e.target.value < +open2?.measureCount &&
                        e.target.value >= 0) ||
                      e.target.value === ""
                    ) {
                      console.log(
                        Number(+e.target.value),
                        Number(payload2.completedCount),
                        "uhuzzzihuhh"
                      );

                      setPayload2({
                        ...payload2,
                        completedCount:
                          e.target.value === "" ? "" : Number(+e.target.value),
                      });
                    }
                  }}
                  label={"Completed Count"}
                />
              </Box>
              <Box sx={{ p: 1 }}>
                {/* <Box sx={{ fontSize: 11, mb: 1 }}>Mandatory*</Box> */}

                <TextField
                  variant="outlined"
                  inputProps={{ maxLength: 100 }}
                  type="text"
                  multiline
                  required
                  // maxRows={4}
                  fullWidth
                  rows={3}
                  // value={assignedTaskList[index]?.remarks}

                  onChange={(e) => {
                    setPayload2({ ...payload2, remarks: e.target.value });
                  }}
                  label={"Remarks"}
                />
              </Box>
            </DialogContent>

            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="small"
                variant="contained"
                onClick={revertSubmit}
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,

                  ...allpageFontStyle.buttonFontStyle2,
                  backgroundColor: "#258d4d",
                  ":hover": { backgroundColor: "green" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                autoFocus
                disabled={
                  payload2.remarks.length === 0 ||
                  payload2.completedCount.length === 0
                }
              >
                Submit
              </Button>

              <Button
                size="small"
                color="error"
                variant="contained"
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,
                  p: 0,
                  px: 2,
                  ...allpageFontStyle.buttonFontStyle2,
                  // backgroundColor: "#bf6373",
                  // ":hover": { backgroundColor: "red" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                onClick={handleClose2}
                autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={Boolean(open3)}>
            <DialogTitle sx={{ ...allpageFontStyle.title }}>
              Update your completed count for {open3?.taskId}
            </DialogTitle>

            <DialogContent>
              <Box sx={{ p: 1 }}>
                {/* <Box sx={{ fontSize: 11, mb: 1 }}>Mandatory*</Box> */}

                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{ max: open3.measureCount, min: 0 }}
                  // type="number"
                  required
                  value={payload3.completeCount}
                  // value={assignedTaskList[index]?.remarks}

                  onChange={(e) => {
                    if (
                      (+e.target.value <= +open3.measureCount &&
                        e.target.value >= 0) ||
                      e.target.value === ""
                    ) {
                      // console.log('aduhuidg')
                      setPayload3({
                        ...payload3,
                        completeCount:
                          e.target.value === "" ? "" : +e.target.value,
                      });
                    }
                  }}
                  label={"Completed Count"}
                />
              </Box>
            </DialogContent>

            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="small"
                variant="contained"
                onClick={countSubmit}
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,

                  fontWeight: "bold",
                  backgroundColor: "#258d4d",
                  ":hover": { backgroundColor: "green" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                // autoFocus
              >
                Submit
              </Button>

              <Button
                color="error"
                size="small"
                variant="contained"
                sx={{
                  color: "white",
                  // width: '10%',
                  height: 30,

                  fontWeight: "bold",
                  // backgroundColor: "#bf6373",
                  // ":hover": { backgroundColor: "red" },
                  borderRadius: 5,
                  textTransform: "capitalize",
                }}
                onClick={handleClose3}
                // autoFocus
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {/* </Box> */}
        </Box>
      </Card>
    </Box>
  );
}
