/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
import {
  Box,
  Button,
  Card,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGride, {
  headerBackground,
} from "../../components/datagride/DataGride";

import { Delete, Expand, ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import SelectComponent from "../../components/commonComponents/SelectComponent";
import EyeIcon from "../../components/commonComponents/eyeicon";
import user from "../../pics/user (1).png";
import {
  AssignTaskAction,
  DownloadAssignedTask,
  TaskAssignExcelUploadAction,
  getJobByProjectAction,
  getProjectListAction,
  getTaskAction,
} from "../../redux/action/action";
import { updateMasterStates } from "../../redux/reducers/masterReducer";
import { ReassignTaskData } from "../../service/taskService";
import { ImageTag, imageImports } from "../../utils/Imageimport";
import { formContainerCardStyle } from "../../utils/Style";
import {
  CustomButton,
  CustomDialog,
  PopupMessage,
  ProgressBar,
  TriggerAlert,
  dynamicolum,
} from "../../utils/UIUtil";
import { allowedPages } from "../../utils/permissions";
import {
  JobDetailsComponet,
  assignConditionType,
  assigneErrorColumns,
  reassignColumns,
} from "./TaskJobData";
import { updatePresistStates } from "../../redux/reducers/PresistReducer";
import { RESET_LABEL } from "../../utils/textName";
import { allpageFontStyle } from "../../utils/FontStyle";
import PopupComponent from "../../components/commonComponents/PopupComponent";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
export default function TaskMapping() {
  const { projectList, jobList, tasklistData } = useSelector(
    (state) => state.master
  );
  const insialState = {
    task: false,
    revert: false,
    split: false,
    revertList: [],
    colorUnassignedTaskList: [],
    popupstate: false,
    popupmessage: "",
    splitList: [],
  };
  const inicialPopuState = {
    state: false,
    responseData: "",
    columns: reassignColumns,
    reassignErrorColumeList: [],
    reassignEmployeeList: null,
  };
  const [selectedFile, setSelectedFile] = React.useState([]);
  const [projectCode1, setprojectCode1] = useState("");
  const [jobCode1, setjobCode1] = useState("");
  const [checkedState, setCheckedState] = useState(insialState);
  const [downloadState, setDownloadState] = useState(false);
  const [checkedStateFieldValue, setCheckedStateFieldValue] = useState("");
  const [category1, setcategory1] = useState("");
  const [categoryType1, setcategoryType1] = useState("");
  const [assignCondition, setAssignCondition] = useState("");
  const [loadData, setLoadData] = useState("");
  const [reassignPopup, setReassignPopup] = useState(inicialPopuState);
  // const [pageNumber, setPageNumber] = useState(1);
  // const [itemPerPage, setItemPerPage] = useState(1000);
  // const [totalPage, setTotalPage] = useState(0);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  // const { employeeList } = useSelector((state) => state.profile);
  const [selectionIs, setSelectionIs] = React.useState(false);
  const [expand, setExpand] = React.useState(false);
  const [isActive, setIsactive] = React.useState(false);
  const [selectionState, setSelectionState] = React.useState([]);
  const [selectionRow, setSelectionRow] = React.useState([]);
  const [constructData, setConstructData] = React.useState([]);
  const [dynamicValue, setDynamicValue] = React.useState([]);
  const [columns2, setColumns2] = React.useState(dynamicolum || []);
  const [usersList, setUsersList] = React.useState([]);
  const [treeList, setTreeList] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [keyValue, setkeyValue] = useState([]);
  const [downloadExcelPayload, setDownloadExcelPayload] = useState([]);
  const [fileValue, setFileValue] = React.useState();
  const [unAssignedTaskData, setUnAssignedTaskData] = React.useState([]);
  const fileRef1 = React.useRef(null);
  const [assginedData, setAssginedData] = React.useState({
    userInfo: {},
    taskInfo: [],
  });
  const [data, setData] = useState({});
  const [taskList, setTaskList] = React.useState([]);

  const { globapOrganization, autoLoad } = useSelector(
    (state) => state.presist
  );

  const [loading, setLoading] = React.useState(false);
  const [loading1, setLoading1] = React.useState(false);
  const [alertPopup, setAlertPopup] = React.useState({
    open: false,
    type: "",
    title: "",
  });
  const handelResult = () => {
    if (alertPopup.type === PopupMessage.listType) {
    } else if (alertPopup.type === PopupMessage.resetType) {
      handelClearAll();
    } else if (alertPopup.type === PopupMessage.viewType) {
    } else if (alertPopup.type === PopupMessage.editType) {
    }
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handelCancel = () => {
    setAlertPopup((per) => ({ ...per, open: false }));
  };
  const handleClosepopup = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading(false);
  };
  const handleClosepopup1 = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setLoading1(false);
  };

  const handelClosePopupMessage = () => {
    setCheckedState({
      ...checkedState,
      popupstate: false,
      split: false,
      revert: false,
      task: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setData("");
  };
  const [payload, setpayload] = React.useState({
    jobcode: "",
    type: "",
    projectCode: "",
  });
  const constructProjectData = (arr) => {
    const res = {
      projectInfo: arr?.projectInfo[0],
      jobList: arr.jobList,
      jobCountInfo: arr.jobTaskInfo,
      taskCategory: ["All", ...arr.taskCategory],
      taskType: ["All", ...arr.taskType],
      userInfo:
        arr.users &&
        arr.users.length > 0 &&
        arr.users.map((val) => val.userInfo),
    };
    return res;
  };
  // React.useEffect(() => {
  //   return () => {
  //     setItemPerPage(1000);
  //     setPageNumber(1);
  //     setTotalPage(0);
  //   };
  // }, []);
  React.useEffect(() => {
    if (jobList?.jobList?.length > 0) {
      const taskListData = constructProjectData(jobList);
      setConstructData(taskListData);
      setUsersList(taskListData.userInfo);
    }
  }, [jobList]);
  const constructTaskData = (arr) => {
    const res = arr.map((val) =>
      val.taskList.map((el, i) => ({
        id: i + 1,
        ...el.taskInfo,
        // taskId: i === 6 ? el?.taskInfo?.taskId + "_R" : i === 10 ? el?.taskInfo?.taskId + "_R" : el?.taskInfo?.taskId,
        createdUserInfo: el?.createdUserInfo,
        updatedUserInfo: el?.updatedUserInfo,
        jobInfo: val?.jobInfo,
        // ...val.jobInfo.displayColumns.split(',').map((cl) => ({
        //   [cl]: el.taskInfo?.taskInfo[cl]
        // })),
        dynamicData: val?.jobInfo.displayColumns.split(",").map((cl) => ({
          field: cl,
          headerName: cl,
          // flex: 3,
          width:
            el?.taskInfo?.taskInfo[cl].toString().length <= cl.toString().length
              ? (cl.toString().length / 10) * 100
              : (el?.taskInfo?.taskInfo[cl].toString().length / 10) * 100,
          // width: 200,
          // renderCell: (params) => <Box sx={{ maxWidth: 100, overflowX: 'auto', fontFamily: "Lato" }} >{params?.row?.taskInfo[cl]}</Box>,
          valueGetter: (params) => {
            return `${params.row.taskInfo[cl] || ""}`;
          },
          align: "left",
          headerAlign: "left",
        })),
      }))
    );
    return res.flat();
  };
  const constructDynamicData = (arr) => {
    if (arr && arr[0]?.dynamicData) {
      const res = setColumns2((per) => [...arr[0]?.dynamicData, ...per]);
      return res;
    }
  };
  React.useEffect(() => {
    const day = async () => {
      try {
        if (tasklistData && tasklistData[0]?.taskList?.length > 0) {
          await setColumns2(dynamicolum);
          const taskListData = constructTaskData(tasklistData);
          const dynamicData = constructDynamicData(taskListData);
          await setDynamicValue(dynamicData);
          await setIsactive(!isActive);
          if (taskListData && treeList && treeList.length > 0) {
            const findD = treeList?.map((val) => val.taskInfo) || [];
            const rendertheTask = taskListData.filter(
              (val) =>
                !findD.flat().find((el) => {
                  return el.taskId === val.taskId;
                })
            );
            setTaskList(rendertheTask.flat());
          } else {
            await setTaskList(taskListData);
          }
          // await setTaskList((per) => {
          //   const arrayOfObjects = [...per, ...taskListData]
          //   const uniqueArray = removeDuplicatesFromArray(arrayOfObjects);
          //   return uniqueArray
          // });
          // setUsersList(taskListData.userInfo);
        } else {
          setTaskList([]);
        }
      } catch (error) {}
    };
    day();
  }, [tasklistData]);

  React.useEffect(() => {
    dispatch(getProjectListAction(globapOrganization?.orgId));
    handelClearAll();
  }, [globapOrganization]);
  const handelProject = async (e) => {
    if (e) {
      setpayload({ ...payload, jobcode: "" });
      setjobCode1("");
      setUnAssignedTaskData([]);
      setTaskList([]);
      setFileValue("");
      setTreeList([]);
      setCheckedState(insialState);
      setpayload({ ...payload, projectCode: e?.projectCode });
      await dispatch(
        getJobByProjectAction({
          projectCode: e?.projectCode,
          orgId: globapOrganization.orgId,
        })
      );
      setprojectCode1(e);
    } else {
      setprojectCode1(e);
      setExpand(false);
      setCheckedState(insialState);
      setpayload({ ...payload, jobcode: e });
      setjobCode1(e);
      setTaskList([]);
      setConstructData([]);
      setUsersList([]);
    }
  };
  const handelSelect = (e, type) => {
    if (type === "job") {
      if (e) {
        setpayload({ ...payload, jobcode: e?.jobCode });
        setjobCode1(e);
      } else {
        setpayload({ ...payload, jobcode: e });
        setjobCode1(e);
      }
    }
    if (type === "taskType") {
      if (e) {
        setpayload({ ...payload, taskType: e });
        setcategoryType1(e);
      } else {
        setpayload({ ...payload, taskType: "All" });
        setcategoryType1("All");
      }
    }
    if (type === "category") {
      if (e) {
        setpayload({ ...payload, type: e });
        setcategory1(e);
      } else {
        setpayload({ ...payload, type: "All" });
        setcategory1("All");
      }
    }
    if (type === "assignType") {
      if (e) {
        setAssignCondition(e);
      } else {
        setAssignCondition(e);
      }
    }
  };
  const dynamicFun = (arr) => {
    const column =
      arr?.columnHeaders &&
      arr?.columnHeaders?.length > 0 &&
      arr?.columnHeaders?.map((i, index) => {
        return {
          field: i.key,
          headerName: i.headerName,
          align: "left",
          headerAlign: "left",
          minWidth: 250,
          renderCell: (params) => (
            <span
              style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              title={params.row?.taskInfo[i.key]}
            >
              {params.row?.taskInfo[i.key]}
            </span>
          ),
        };
      });
    const dynamic = [...column, ...reassignColumns];
    return dynamic;
  };
  const handelChangeFile = async (e) => {
    try {
      setFileValue(e.target.files[0]);
      await dispatch(
        TaskAssignExcelUploadAction(
          "post",
          {},
          e.target.files[0],
          setLoading1,
          "/job/uploadTaskExcel/"
        )
      ).then((res) => {
        console.log(res, "res123");
        if (res?.isConfirmation || res?.response?.data?.isConfirmation) {
          console.log(res, "res123");
          console.log(res, "res123");
          setReassignPopup({
            ...reassignPopup,
            state: true,
            columns: res?.isConfirmation ? dynamicFun(res) : [],
            responseData: res || res?.response?.data,
            reassignErrorColumeList: res?.response?.data?.details,
            reassignEmployeeList: res?.details || "string",
          });
        } else {
          assign(() => {
            TriggerAlert("success", res?.message);
          });
        }
      });
      fileRef1.current.value = null;
    } catch (err) {
      clearField();
    }
  };
  console.log(reassignPopup, "reassignPopup");
  const clearField = () => {
    fileRef1.current.value = null;
    setFileValue("");
  };
  const columns1 = [
    {
      field: "firstname",
      headerName: "Name",
      width: 0,
      height: 0,
      flex: 1,
      fontSize: 10,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        return (
          <div>
            {params.row.firstname || ""} {params.row.lastname || ""} -{" "}
            <span style={{ fontSize: 12 }}>({params.row.empId})</span>
          </div>
        );
      },
    },
  ];
  const onSelectionModelChange = (row) => {
    // const fullName = row.row.firstname + " " + row.row.lastname
    setAssginedData({ ...assginedData, userInfo: row?.row });

    // setSelectedFile(row);
    // if (row.length > 0) {
    //   setSelectionIs(true);
    //   setSelectionState(row);
    // } else {
    //   setSelectionIs(false);
    // }
  };
  const onSelectionModelChange2 = (row) => {
    setSelectedFile(row);
    if (row.length > 0) {
      const t = usersList.filter((match) => row.includes(match.id));
      setAssginedData({ ...assginedData, userInfo: t });
      setSelectionIs(true);
      setSelectionState(row);
    } else {
      setSelectionIs(false);
      setAssginedData({ ...assginedData, userInfo: [] });
    }
  };
  const selectedRow = (data) => {
    if (data.length > 0) {
      setSelectionRow(data);
      const t = taskList.filter((match) => data.includes(match.id));
      setAssginedData({ ...assginedData, taskInfo: t });
    } else {
      setSelectionRow(data);
      setAssginedData({ ...assginedData, taskInfo: [] });
    }
  };
  function assignArrayToUsers(array, userArray) {
    const assignedArrayLength = Math.floor(array.length / userArray.length);

    const assignedArray = userArray.map((user, index) => ({
      userInfo: user,
      taskInfo: array.slice(
        index * assignedArrayLength,
        (index + 1) * assignedArrayLength
      ),
    }));
    const filterAssignArray = assignedArray.map((val) => val.taskInfo) || [];
    const arrayFilter =
      array &&
      array.length > 0 &&
      array.filter(
        (val) =>
          !filterAssignArray.flat().find((el) => {
            return el.id === val.id;
          })
      );
    return {
      assignedArr: assignedArray,
      unAssignedtask: [...arrayFilter] || [],
    };
  }
  // function assignArrayToUsers(array, userArray) {
  //   const assignedArrayLength = Math.floor(array.length / userArray.length);
  //   const remainingElements = array.length % userArray.length;
  //   const assignedArray = [];

  //   let startIndex = 0;

  //   userArray.forEach((user, index) => {
  //     const extraElement = index < remainingElements ? 1 : 0;
  //     const endIndex = startIndex + assignedArrayLength + extraElement;
  //     const assignedArraySlice = array.slice(startIndex, endIndex);

  //     assignedArray.push({
  //       user: user,
  //       assignedArray: assignedArraySlice
  //     });

  //     startIndex = endIndex;
  //   });

  //   const remainingArray = array.slice(startIndex);

  //   return {
  //     assignedArray: assignedArray,
  //     remainingArray: remainingArray
  //   };
  // }

  const assign = async (callback) => {
    if (payload?.jobcode) {
      const load = {
        projectCode: payload.projectCode ? payload.projectCode : "",
        jobCode: payload.jobcode ? payload.jobcode : "",
        category:
          category1 !== "All" ? (payload.type ? payload.type : "all") : "all",
        taskType:
          categoryType1 !== "All"
            ? payload.taskType
              ? payload.taskType
              : "all"
            : "all",
        reassign: false,
      };
      setLoadData(load);
      // const load = {
      //   projectCode: payload.projectCode ? payload.projectCode : "",
      //   jobCode: payload.jobcode  ? payload.jobcode : "all",
      //   category: payload.type !== "All" ? payload.type : "all",
      //   taskType: payload.taskType !== "All" ? payload.taskType : "all",
      //   reassign: false,
      // };
      await dispatch(getTaskAction("post", load, setLoading, null, callback));
      // `?pageSize=${itemPerPage}&pageNumber=${pageNumber}`
      setSelectionRow([]);
      setSelectedFile([]);
      setUnAssignedTaskData([]);
      setCheckedState(insialState);
    } else {
      TriggerAlert("warning", "Select Jobcode");
    }
  };
  // Page Auto Reload By the Task Status changes

  // useEffect(() => {
  //   if (autoLoad?.isAutoLoad) {
  //     assign()
  //     dispatch(updatePresistStates({ name: "autoLoad", value: { isAutoLoad: false, }, }));
  //   }
  // }, [autoLoad])

  const projectDetails = {
    fontFamily: "lato",
    fontWeight: "bold",
    fontSize: "14px",
  };
  const projectContent = {
    fontFamily: "lato",
    fontSize: "14px",
    pl: 1,
  };
  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };
  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };
  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? treeList.map((val) => val.userInfo.id.toString())
        : []
    );
  };
  const handelremovetreeData = (row, tree, task, typeDelete) => {
    const treedata = [...treeList];
    if (
      (loadData?.taskType === row?.taskType || loadData?.taskType === "all") &&
      (loadData.category === row?.category || loadData.category === "all")
    ) {
      const arr = [...taskList, row];
      setTaskList(arr);
      treedata[tree].taskInfo.splice(task, 1);
      const res1 = treedata[tree].taskInfo;
      if (res1.length === 0) {
        treedata.splice(tree, 1);
      }
      if (treedata.length === 0 && res1.length === 0) {
        treedata.splice(tree, 1);
      }
    } else {
      treedata[tree].taskInfo.splice(task, 1);
      const res1 = treedata[tree].taskInfo;
      if (res1.length === 0) {
        treedata.splice(tree, 1);
      }
      if (treedata.length === 0 && res1.length === 0) {
        treedata.splice(tree, 1);
      }
    }
    setTreeList(treedata);
  };
  const handelDeleteUserAndTask = (i) => {
    try {
      const res = treeList[i]?.taskInfo.slice();
      const treedata = [...treeList[i]?.taskInfo];
      const arr = [...taskList];
      const res1 = res.map((val, task) => {
        if (
          (loadData?.taskType === val?.taskType ||
            loadData?.taskType === "all") &&
          (loadData?.category === val?.category || loadData?.category === "all")
        ) {
          arr.push(val);

          treedata.splice(task, 1);
        } else {
          treedata.splice(task, 1);
        }
      });

      const copyArr = [...treeList];
      copyArr.splice(i, 1);
      setTaskList(arr);
      setTreeList(copyArr);
    } catch (error) {}
  };

  React.useEffect(() => {
    return () => {
      dispatch(
        updateMasterStates({
          name: "jobList",
          value: {},
        })
      );
      dispatch(
        updateMasterStates({
          name: "tasklistData",
          value: [],
        })
      );
      handelClearAll();
    };
  }, []);
  const handelClearAll = () => {
    dispatch(
      updateMasterStates({
        name: "jobList",
        value: {},
      })
    );
    dispatch(
      updateMasterStates({
        name: "tasklistData",
        value: [],
      })
    );
    setpayload({ ...payload, jobcode: "", type: "", projectCode: "" });
    setConstructData([]);
    setUsersList([]);
    setTaskList([]);
    setTreeList([]);
    setUnAssignedTaskData([]);
    setprojectCode1("");
    setjobCode1("");
    setcategory1("");
    setcategoryType1("");
  };
  const divideArray = (array, value, userArray) => {
    const resultArray = [];
    const chunkSize = Math.ceil(array.length / value);

    for (let i = 0; i < userArray.length; i++) {
      const chunk = array.slice(i, i + chunkSize);
      if (value === chunkSize.length) {
        const res = {
          userInfo: userArray[i],
          taskInfo: chunk,
        };
        resultArray.push(res);
      }
    }

    return resultArray;
  };
  const assginthearrayAsSerialicedOrder = (array, value, users) => {
    try {
      let arr = [...array];
      let balence = [];
      let assignedTaskInfo = [];
      while (arr.length > 0) {
        users.map((val) => {
          if (arr.length >= value) {
            const spliced = arr.splice(0, value);
            let foundUser = assignedTaskInfo.findIndex(
              (info) => info.userInfo.empId === val.empId
            );
            if (foundUser >= 0) {
              assignedTaskInfo[foundUser].taskInfo.push(...spliced); // Assign task to the existing user
            } else {
              assignedTaskInfo.push({ userInfo: val, taskInfo: spliced }); // Assign task to a new user
            }
          } else {
            if (balence.length === 0) {
              balence = [...arr];
              arr = [];
            }
          }
        });
      }

      return { assignedTask: assignedTaskInfo, unAssignedTaskData: balence };
    } catch (error) {}
  };

  const constructDataManultaskDivide = (manualDivideArr) => {
    try {
      const findD = manualDivideArr?.map((val) => val.taskInfo) || [];
      setSelectedFile([]);
      setSelectionRow([]);
      const rendertheTask = taskList.filter(
        (val) =>
          !findD.flat().find((el) => {
            return el.id === val.id;
          })
      );
      setTaskList(rendertheTask.flat());
      if (treeList.length > 0) {
        manualDivideArr.map((val, i) => {
          const det = treeList.findIndex(
            (el) => el.userInfo.username === val.userInfo.username
          );
          if (det !== -1) {
            const constructDataTree = manualDivideArr[det]?.taskInfo;
            treeList[det].taskInfo = [
              ...treeList[det].taskInfo,
              ...constructDataTree,
            ];
          } else {
            const getre = [val];
            setTreeList((per) => [...per, ...getre]);
          }
        });
      } else {
        setTreeList((per) => [...per, ...manualDivideArr]);
      }
    } catch (err) {}
  };
  const constructdataAutomate = (d) => {
    setSelectedFile([]);
    setSelectionRow([]);
    if (assginedData.userInfo.length > 1) {
      const findD = d && d.length > 0 && d.map((val) => val.taskInfo);
      const rendertheTask = taskList.filter(
        (val) =>
          !findD.flat().find((el) => {
            return el.id === val.id;
          })
      );
      setTaskList(rendertheTask.flat());
      if (treeList.length > 0) {
        d.map((val, i) => {
          const det = treeList.findIndex(
            (el) => el.userInfo.username === val.userInfo.username
          );
          if (det !== -1) {
            const constructDataTree = d[det].taskInfo;
            treeList[det].taskInfo = [
              ...treeList[det].taskInfo,
              ...constructDataTree,
            ];
          } else {
            const getre = [val];
            setTreeList((per) => [...per, ...getre]);
          }
        });
      } else {
        setTreeList((per) => [...per, ...d]);
        // setTreeList(arr);
      }
    } else {
      const arr = [];
      const findD = d && d.length > 0 && d.map((val) => val.taskInfo);
      const rendertheTask = taskList.filter(
        (val) =>
          !findD.flat().find((el) => {
            return el.id === val.id;
          })
      );
      setTaskList(rendertheTask.flat());
      if (treeList.length > 0) {
        const res = treeList.findIndex(
          (val, i) => d[0].userInfo.username === val.userInfo.username
        );
        if (res !== -1) {
          const constructDataTree = d[0].taskInfo;
          treeList[res].taskInfo = [
            ...treeList[res].taskInfo,
            ...constructDataTree,
          ];
        } else {
          setTreeList((per) => [...per, ...d]);
        }
      } else {
        setTreeList((per) => [...per, ...d]);
      }
    }
  };
  const handelAssginFun = async () => {
    setCheckedState(insialState);
    if (checkedStateFieldValue) {
      const userData =
        usersList &&
        usersList.length > 0 &&
        usersList.filter((val) =>
          assginedData.userInfo.find((el) => el.empId === val?.empId)
        );
      const taskData =
        taskList &&
        taskList.length > 0 &&
        taskList.filter((val) =>
          assginedData.taskInfo.find((el) => el.taskId === val?.taskId)
        );
      const manualDivideArr = assginthearrayAsSerialicedOrder(
        taskData,
        +checkedStateFieldValue,
        userData
      );
      // assginthearrayAsSerialicedOrder(assginedData.taskInfo, +checkedStateFieldValue, assginedData.userInfo)
      constructDataManultaskDivide(manualDivideArr?.assignedTask);
      await setIsactive(!isActive);
      await setUnAssignedTaskData(manualDivideArr?.unAssignedTaskData);
      setCheckedStateFieldValue("");
    } else {
      const res = assignArrayToUsers(
        assginedData.taskInfo,
        assginedData.userInfo
      );
      constructdataAutomate(res?.assignedArr);
      await setUnAssignedTaskData(res?.unAssignedtask);
    }
    setSelectedFile([]);
    setSelectionRow([]);
  };
  const constructPayloadData = (arr) => {
    const res = arr.map((val) => ({
      username: val.userInfo.empId,
      taskList: val.taskInfo.map((al) => ({
        taskId: al.taskId,
        jobCode: al.jobInfo.jobCode,
      })),
    }));
    return res.flat();
  };
  const handleSubmit = async (downloadState) => {
    const constructPayload = constructPayloadData(treeList);
    await dispatch(AssignTaskAction("post", constructPayload)).then(
      async (res) => {
        // TriggerAlert('success', "Task Assgined")
        await setDownloadExcelPayload(constructPayload);
        await setDownloadState(true);
        // assign()
        // setItemPerPage(100);
        // setPageNumber(1);
        setTreeList([]);
        setCheckedStateFieldValue("");
        // setDownloadState(false)
      }
    );
  };
  const submitOnclick = async (download) => {
    if (download === "yes") {
      // if (download) {
      await dispatch(
        DownloadAssignedTask(
          "post",
          downloadExcelPayload,
          "/job/assign_excel_list/",
          downloadExcelPayload?.[0]?.taskList?.[0]?.jobCode || ""
        )
      );
      setDownloadState(false);
      // } else {
      //   setDownloadState(false);
      // }
    }
    if (!download) {
      setDownloadState(false);
    }
    if (download === "NoReassign") {
      await setReassignPopup(inicialPopuState);
      // assign();
    }
    if (download === "Reassign") {
      const payload = {
        projectCode: reassignPopup?.responseData?.projectCode,
        jobCode: reassignPopup?.responseData?.jobCode,
        reAssignTasks: reassignPopup?.reassignEmployeeList,
      };
      const res = await ReassignTaskData(
        "post",
        payload,
        "/job/updateTaskExcel/"
      );

      if (res) {
        TriggerAlert("success", res?.data?.message);
        setReassignPopup(inicialPopuState);
      }
    }
  };
  // React.useEffect(() => {
  //   if (payload?.jobcode) {
  //     assign()
  //   }
  // }, [globapOrganization, pageNumber, itemPerPage]);
  // useEffect(() => {
  //   if (tasklistData?.pageInfo?.num_pages) {
  //     setTotalPage(tasklistData.pageInfo?.total_items);
  //   } else {
  //     setTotalPage(0);
  //   }
  // }, [tasklistData]);

  // function CustomFooterStatusComponent(params) {
  //   // const { count } = props
  //   return (
  //     <Box sx={{ p: 1, display: "flex", justifyContent: "space-between" }}>
  //       <Box>
  //         <b>Total Row:</b>
  //         <span style={{ paddingLeft: 5 }}>{totalPage}</span>
  //       </Box>
  //       <Box>
  //         {" "}
  //         <b>Selected Row Count:</b>
  //         <span>{selectionRow?.length}</span>
  //       </Box>
  //     </Box>
  //   );
  // }

  const handelCountField = (e) => {
    if (
      (+e.target.value <= +assginedData.taskInfo.length &&
        e.target.value > 0) ||
      e.target.value === ""
    )
      setCheckedStateFieldValue(e.target.value);
  };
  const handelDownloadClick = () => {
    if (payload?.jobcode && payload.projectCode) {
      const load = {
        projectCode: payload.projectCode ? payload.projectCode : "",
        jobCode: payload.jobcode ? payload.jobcode : "",
        category:
          category1 !== "All" ? (payload.type ? payload.type : "all") : "all",
        taskType:
          categoryType1 !== "All"
            ? payload.taskType
              ? payload.taskType
              : "all"
            : "all",
        reassign: false,
        orgId: globapOrganization.orgId,
      };
      dispatch(DownloadAssignedTask("post", load, "/job/assignTaskExcel/"));
    } else {
      TriggerAlert("warning", "Select Project and Job ");
    }
  };
  useEffect(() => {
    // if (constructData.jobList && constructData.jobList.length > 0) {
    //   setjobCode1(constructData.jobList[0])
    //   setpayload({ ...payload, jobcode: constructData.jobList[0]?.jobCode });
    // }
    if (constructData.taskType && constructData.taskType.length > 0) {
      setcategoryType1(constructData.taskType[0]);
      setpayload({ ...payload, type: constructData.taskType[0] });
    }
    if (assignConditionType && assignConditionType.length > 0) {
      setAssignCondition(assignConditionType[0]);
    }
    if (constructData.taskCategory && constructData.taskCategory.length > 0) {
      setcategory1(constructData.taskCategory[0]);
      setpayload({ ...payload, taskType: constructData.taskCategory[0] });
    }
  }, [projectCode1]);

  const handelDeletAllTask = () => {
    const treearr = [...treeList];
    const taskarr = treearr.map((val) => val?.taskInfo?.map((el) => el));
    const mainArrTask = [...taskarr.flat()];
    const pushData = [...taskList];
    const res1 = mainArrTask.map((val, task) => {
      if (
        (loadData?.taskType === val?.taskType ||
          loadData?.taskType === "all") &&
        (loadData?.category === val?.category || loadData?.category === "all")
      ) {
        pushData.push(val);
      } else {
        setTreeList([]);
      }
    });
    setTaskList(pushData);
    setTreeList([]);
  };
  const checkBoxFilter = () => {
    const handelcheck = (e, type, i) => {
      if (type === "autoRemainTask") {
        if (e) {
          const value = e.target.checked;
          const arr = [...unAssignedTaskData];
          if (!arr.length > 0) {
            setCheckedState({
              ...checkedState,
              task: value,
              revertList: [],
              colorUnassignedTaskList: arr,
              popupstate: true,
              popupmessage: "No Exceeded File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              task: value,
              revert: false,
              split: false,
              splitList: [],
              revertList: [],
              colorUnassignedTaskList: arr,
            });
          }
        }
      }
      if (type === "revert") {
        if (e) {
          const value = e.target.checked;
          const taskLoad = [...taskList];
          const taskLoadFilter = taskLoad.filter((val) => val?.revertedFrom);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              revert: value,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Reverted File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              revert: value,
              split: false,
              task: false,
              splitList: [],
              revertList: taskLoadFilter,
              colorUnassignedTaskList: [],
            });
          }
        }
      }
      if (type === "split") {
        if (e) {
          const value = e.target.checked;
          const taskLoad = [...taskList];
          const taskLoadFilter = taskLoad.filter((val) => val?.splitFromTask);
          if (!taskLoadFilter.length > 0) {
            setCheckedState({
              ...checkedState,
              split: value,
              revertList: [],
              colorUnassignedTaskList: [],
              popupstate: true,
              popupmessage: "No Split File To Display",
            });
          } else {
            setCheckedState({
              ...checkedState,
              split: value,
              task: false,
              revert: false,
              splitList: taskLoadFilter,
              revertList: [],
              colorUnassignedTaskList: [],
            });
          }
        }
      }
    };
    return (
      <Box sx={{ display: "flex", gap: 3, p: 1 }}>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#ffc9c9", ...allpageFontStyle.typography1 }}
            // disabled={checkedState.revert || checkedState.task}
            checked={checkedState.split}
            onChange={(e) => handelcheck(e, "split")}
            id={"split"}
            name={"split"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"split"}>
            {"Splitted"}
          </label>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#9cdeec", ...allpageFontStyle.typography1 }}
            // disabled={checkedState.revert || checkedState.split}
            checked={checkedState.task}
            onChange={(e) => handelcheck(e, "autoRemainTask")}
            id={"autoRemainTask"}
            name={"autoRemainTask"}
          />
          <label
            style={{ ...allpageFontStyle.typography1 }}
            for={"autoRemainTask"}
          >
            {"Count Exceeded"}
          </label>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-end", gap: 0.5 }}>
          <input
            type="checkbox"
            style={{ accentColor: "#f0b878", ...allpageFontStyle.typography1 }}
            //  disabled={checkedState.split || checkedState.task}
            checked={checkedState.revert}
            onChange={(e) => handelcheck(e, "revert")}
            id={"revert"}
            name={"revert"}
          />
          <label style={{ ...allpageFontStyle.typography1 }} for={"revert"}>
            {"Reverted"}
          </label>
        </Box>
      </Box>
    );
  };
  const handelRowColor = (params) => {
    return params?.row?.revertedFrom
      ? "super-app-theme--Revert"
      : params?.row?.splitFromTask
      ? "super-app-theme--Split"
      : "" ||
        (treeList &&
          treeList.length > 0 &&
          unAssignedTaskData &&
          unAssignedTaskData.length > 0 &&
          unAssignedTaskData.map((val) =>
            val.id === params?.row?.id ? "super-app-theme--Open" : ""
          ));
  };
  // useEffect(() => {
  //   if (unAssignedTaskData && unAssignedTaskData.length > 0) {
  //     handelRowColor()
  //   }
  // }, [unAssignedTaskData]);
  return (
    <Box
      sx={{
        px: 5,
        pt: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        // background:'#2b98f7',
        minHeight: 650,
        overflow: "hidden",
        background: grey[100],
      }}
    >
      <Card sx={{ ...formContainerCardStyle, p: 3, overflow: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "center", px: 5 }}>
          <SelectComponent
            DataOptions={projectList?.project_details}
            labelName={(option) => option.projectCode}
            // disabled={!treeList.length > 0 }
            value={projectCode1}
            sx={{ pr: 1 }}
            label={"Project *"}
            handelChange={handelProject}
          />
          <SelectComponent
            DataOptions={constructData.jobList}
            labelName={(option) => option.jobCode}
            defaultValue={jobCode1}
            disabled={treeList.length > 0}
            value={jobCode1}
            sx={{ pr: 1 }}
            label={"Job *"}
            handelChange={(e) => handelSelect(e, "job")}
          />
          <SelectComponent
            DataOptions={constructData.taskCategory}
            labelName={(option) => option}
            defaultValue={category1}
            value={category1}
            sx={{ pr: 1 }}
            label={"Category"}
            handelChange={(e) => handelSelect(e, "category")}
          />
          <SelectComponent
            DataOptions={constructData.taskType}
            labelName={(option) => option}
            defaultValue={categoryType1}
            value={categoryType1}
            sx={{ pr: 1 }}
            label={"Task Type"}
            handelChange={(e) => handelSelect(e, "taskType")}
          />
          {allowedPages().WorkStation.taskAssignment.assign && (
            <SelectComponent
              DataOptions={assignConditionType}
              labelName={(option) => option.label}
              defaultValue={assignCondition}
              value={assignCondition}
              sx={{ pr: 1 }}
              disabled={!taskList?.length > 0}
              label={"Assign Method"}
              handelChange={(e) => handelSelect(e, "assignType")}
            />
          )}
          <Button
            sx={{
              ml: 2,
              p: 0.4,
              ...allpageFontStyle.buttonFontStyle2,
              borderRadius: 5,
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
            }}
            onClick={() => assign()}
            disabled={!projectCode1 || !jobCode1}
            variant="contained"
          >
            Load
          </Button>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ px: 5, pt: 2 }}>
            {jobCode1?.jobCode ? (
              <JobDetailsComponet
                jobObject={jobCode1}
                jobCountArray={constructData?.jobCountInfo}
              />
            ) : null}
          </Box>
          <Collapse in={expand} timeout="auto" unmountOnExit>
            <Paper
              elevation={0}
              sx={{
                mx: "6.5%",
                px: 2,
                background:
                  "linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={2} sx={{ width: "100%", p: 0 }}>
                  <Grid item xs={4} md={4} lg={3} sx={{ display: "flex" }}>
                    <Typography sx={{ ...projectDetails }}>
                      Project Name:
                    </Typography>
                    <Typography sx={{ ...projectContent }}>
                      {constructData?.projectInfo?.projectName}
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex' }}>
                  <Typography sx={{ ...projectDetails }}>
                    Project Code:
                  </Typography>
                  <Typography sx={{ ...projectContent }}>{constructData?.projectInfo?.projectCode}</Typography>
                </Grid> */}
                  {/* <Grid item xs={6} md={6} lg={6} sx={{ display: 'flex' }}>
                  <Typography sx={{ ...projectDetails }}>
                    Status:
                  </Typography>
                  <Typography sx={{ ...projectContent }}>{constructData?.projectInfo?.status
                  }</Typography>
                </Grid> */}
                  <Grid item xs={4} md={4} lg={5} sx={{ display: "flex" }}>
                    <Typography sx={{ ...projectDetails }}>
                      Project Scope:
                    </Typography>
                    <Paper
                      elevation={0}
                      component={"textarea"}
                      disabled
                      value={constructData?.projectInfo?.scopeOfProject}
                      sx={{
                        width: "80%",
                        ml: 1,
                        height: 50,
                        border: "none",
                        background: "transparent",
                        resize: "none",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={4} sx={{ display: "flex" }}>
                    <Typography sx={{ ...projectDetails }}>Notes:</Typography>
                    <Paper
                      elevation={0}
                      component={"textarea"}
                      disabled
                      value={constructData?.projectInfo?.notes}
                      sx={{
                        width: "80%",
                        ml: 1,
                        height: 50,
                        border: "none",
                        background: "transparent",
                        resize: "none",
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Collapse>{" "}
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            px: 5,
            py: 3,
            // flex: 1,
            // height: "100%",
            // minHeight: 600,
            overflow: "auto",
            // background:'green'
            // maxHeight:'85%'
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={3}
            sx={{
              // height: "100%",
              // flex: 1,
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "lavender",
              overflow: "hidden",
              mt: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                ...allpageFontStyle.title,
                fontWeight: "bold",
              }}
            >
              Users
            </Box>
            <Box sx={{ mt: 1, flex: 1, overflow: "auto" }}>
              <DataGride
                // columnHeaderHeight={0}
                columns={columns1}
                tableData={usersList || []}
                disableCheckbox={usersList?.length === 0}
                hiddenFooter={true}
                selectedFile={selectedFile}
                rowSelectionModel={selectedFile}
                rowData={onSelectionModelChange}
                onSelectionModelChange={onSelectionModelChange2}
                getRowHeight={() => "auto"}
                // tableData={
                //     employee &&
                //     employee.length > 0 &&
                //     employee.filter((value) => value.isActive === true)
                // }
                hideDeletion={true}
                customHeight={450}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              // height: "100%",
              // flex: 1,
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "lavender",
              mt: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                //  pr: 5
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  ...allpageFontStyle.title,
                  fontWeight: "bold",
                  gap: 3,

                  pb: 1,
                }}
              >
                <Box sx={{}}> Task List</Box>

                {/* <Box sx={{
                  flex: "0 1 0"
                }} >
                  <SelectComponent
                    DataOptions={assignConditionType}
                    labelName={(option) => option.label}
                    defaultValue={assignCondition}
                    clearIcon={true}
                    value={assignCondition}
                    sx={{
                      width: 140,
                      ".MuiInputBase-input": {
                        height: 13,
                      }
                    }}
                    label={"Assign Method"}
                    handelChange={(e) => handelSelect(e, "assignType")}
                  />
                </Box> */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                {assignCondition?.value === "upload" ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {/* {taskList.length > 0 && payload?.jobcode && payload.projectCode ? ( */}

                    <Box sx={{ p: 0, m: 0 }}>
                      <CustomButton
                        buttonStyle={{ p: 0, m: 0 }}
                        disabled={!taskList.length > 0}
                        color={"success"}
                        label={
                          <Box sx={{ p: 0, m: 0 }}>
                            <Tooltip title=" Excel Download">
                              <Box sx={{ cursor: "pointer" }}>
                                <ImageTag
                                  src={imageImports.excelDownload}
                                  alt="upload"
                                  width={30}
                                  height={30}
                                />
                              </Box>
                            </Tooltip>
                          </Box>
                        }
                        onClick={handelDownloadClick}
                      />
                    </Box>

                    {/* ) : null} */}
                    <Box sx={{ p: 0, m: 0 }}>
                      {/* {taskList.length > 0 &&
                      payload?.jobcode &&
                      payload.projectCode ? ( */}

                      <Box sx={{ p: 0, m: 0 }}>
                        <label htmlFor="upload">
                          <Box>
                            <Tooltip title="Upload excel for manual assign">
                              <Box sx={{ cursor: "pointer" }}>
                                <ImageTag
                                  src={imageImports.excelUpload}
                                  alt="upload"
                                  width={30}
                                  height={30}
                                />
                              </Box>
                            </Tooltip>{" "}
                          </Box>
                        </label>
                      </Box>

                      {/* ) : null} */}
                      {/* <Button component="span">Hi</Button> */}

                      <input
                        type="file"
                        id="upload"
                        ref={fileRef1}
                        onChange={handelChangeFile}
                        style={{ color: "transparent", display: "none" }}
                      />
                    </Box>
                  </Box>
                ) : null}
                {assignCondition?.value === "number" ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      mr: 2,
                    }}
                  >
                    <Box
                      sx={{
                        p: 0,
                        mr: 1,
                      }}
                    >
                      <label>
                        <strong
                          style={{
                            ...allpageFontStyle.typography1,
                            paddingRight: 4,
                          }}
                        >
                          Specific Count{" "}
                        </strong>{" "}
                      </label>
                      <input
                        placeholder="Count"
                        style={{
                          paddingLeft: 20,
                          width: 100,
                          minHeight: 30,
                          height: 30,
                          fontSize: 15,
                          borderRadius: 4,
                          // color: "black",
                        }}
                        // disabled={!checkedState}
                        value={checkedStateFieldValue}
                        onChange={handelCountField}
                      />
                    </Box>
                  </Box>
                ) : null}
                {assignCondition?.value === "auto" &&
                allowedPages().WorkStation.taskAssignment.assign ? (
                  <Box sx={{}}>
                    <Button
                      sx={{
                        borderRadius: 6,
                        marginBottom: 0.5,
                        ...allpageFontStyle.buttonFontStyle2,
                        ":hover": {
                          boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                        },
                      }}
                      size="small"
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      disabled={
                        !(
                          assginedData.userInfo?.length <=
                          assginedData.taskInfo?.length
                        ) ||
                        !assginedData.userInfo?.length > 0 ||
                        !assginedData.taskInfo?.length > 0
                      }
                      onClick={async () => {
                        await handelAssginFun();
                        // setPageNumber(1);
                        // setItemPerPage(100)

                        await setAssginedData({
                          ...assginedData,
                          userInfo: [],
                          taskInfo: [],
                        });
                      }}
                    >
                      Assign
                    </Button>
                  </Box>
                ) : null}
                {assignCondition?.value === "number" ? (
                  <Box sx={{}}>
                    <Button
                      sx={{
                        borderRadius: 5,
                        marginBottom: 0.5,
                        ...allpageFontStyle.buttonFontStyle2,
                        ":hover": {
                          boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                        },
                      }}
                      size="small"
                      variant="contained"
                      endIcon={<KeyboardDoubleArrowRightIcon />}
                      disabled={
                        !(
                          assignCondition?.value === "number" &&
                          checkedStateFieldValue
                        )
                      }
                      onClick={async () => {
                        await handelAssginFun();
                        // setPageNumber(1);
                        // setItemPerPage(100)
                        await setAssginedData({
                          ...assginedData,
                          userInfo: [],
                          taskInfo: [],
                        });
                      }}
                    >
                      Assign
                    </Button>
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              sx={{
                // mt: 1,
                // height: "100%",
                // maxHeight:'45%',
                flex: 1,
                // background:'red',
                overflow: "auto",
                mb: 0,
              }}
            >
              <DataGride
                columns={columns2}
                tableData={
                  checkedState.task
                    ? checkedState.colorUnassignedTaskList
                    : checkedState.revert
                    ? checkedState.revertList
                    : checkedState.split
                    ? checkedState.splitList
                    : taskList || []
                }
                selectedFile={selectionRow}
                disableCheckbox={
                  (checkedState.task &&
                    checkedState.colorUnassignedTaskList?.length === 0) ||
                  (checkedState.revert &&
                    checkedState.revertList?.length === 0) ||
                  (checkedState.split &&
                    checkedState.splitList?.length === 0) ||
                  taskList?.length === 0
                }
                hideDeletion={true}
                // rowData={onSelectionModelChanges}
                filterCheckBox={checkBoxFilter}
                customHeight={450}
                // hiddenFooter={true}
                // hiddenFooter={true}
                // disableCheckbox={true}
                // getRowHeight={() => 'auto'}

                onSelectionModelChange={selectedRow}
                getRowClassName={handelRowColor}
                // slotFooter={{
                //   footer: CustomFooterStatusComponent,
                // }}
                // pageSize={itemPerPage}
                // // getRowHeight={() => 'auto'}
                // page={pageNumber}
                // pageName={"project"}
                // onPageSizeChange={changePageSize}
                // rowCount={totalPage}
                // paginationMode={true}
                // onPageChange={changePage}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={2}
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              // backgroundColor: "lightgreen",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                ...allpageFontStyle.title,
                fontWeight: "bold",
              }}
            >
              Task Assigned
            </Box>

            <Card
              sx={{
                mt: 1,
                borderWidth: 2,
                border: `2px solid ${headerBackground}`,
                height: 450,
              }}
            >
              <Box
                sx={{
                  // flex: 1,
                  height: "100%",
                  // flexGrow: 1,
                  // maxWidth: 200,
                  overflowX: "auto",
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    {" "}
                    {treeList?.length ? (
                      <Button
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={handleExpandClick}
                      >
                        {expanded.length === 0 ? "Expand all" : "Collapse all"}
                        {expanded.length === 0 ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Button>
                    ) : null}
                  </Box>
                  <Box>
                    {treeList.length > 0 ? (
                      <Box
                        onClick={() => {
                          handelDeletAllTask();
                        }}
                        sx={{ pr: 1, pt: 0.5, cursor: "pointer" }}
                      >
                        <Tooltip title="Delete All">
                          {" "}
                          <Delete style={{ fontSize: 21 }} color="error" />
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>

                  {/* <Button onClick={handleSelectClick}>
                  {selected.length === 0 ? "Select all" : "Unselect all"}
                </Button> */}
                </Box>
                {/* <TreeMenu
                data={treeList}
                cacheSearch
                debounceTime={125}
                disableKeyboard={false}
                hasSearch
                onClickItem={function noRefCheck() {}}
                resetOpenNodesOnDataUpdate={false}
              /> */}
                <TreeView
                  aria-label="controlled"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  selected={selected}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleSelect}
                  multiSelect
                >
                  {treeList &&
                    treeList.length > 0 &&
                    treeList.map((val, i) => (
                      <Box>
                        {val.taskInfo?.length ? (
                          <>
                            <TreeItem
                              nodeId={`${val.userInfo.id}`.toString()}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    // pt: 1,
                                  }}
                                >
                                  <Box sx={{ ...allpageFontStyle.typography1 }}>
                                    {val?.userInfo?.firstname +
                                      " " +
                                      val?.userInfo?.lastname}{" "}
                                    ({val?.taskInfo?.length})
                                  </Box>
                                  <Tooltip title="Remove User" placement="top">
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        alt=""
                                        src={user}
                                        style={{ height: 20, width: 20 }}
                                        onClick={() => {
                                          handelDeleteUserAndTask(i);
                                        }}
                                        color="error"
                                      />
                                    </Box>
                                  </Tooltip>{" "}
                                </Box>
                              }
                            >
                              {val.taskInfo.map((va, e) => (
                                <TreeItem
                                  nodeId={`${Math.random()}`.toString()}
                                  label={
                                    <Box
                                      sx={{
                                        display: "flex",
                                        // justifyContent: "space-between",
                                        pt: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{ ...allpageFontStyle.typography1 }}
                                      >
                                        {va.taskId}
                                      </Box>
                                      {/* <Box sx={{ display: "flex" }}> */}
                                      <Box title={"View"}>
                                        <EyeIcon
                                          sx={{
                                            ...allpageFontStyle.IconFontSize,
                                            mt: "0.3rem",
                                          }}
                                          taskId={va.taskId}
                                          jobcode={va.jobCode}
                                          clickOpen={true}
                                          clickclose={false}
                                        />
                                      </Box>
                                      <Box title={"Close"}>
                                        <CloseIcon
                                          color="error"
                                          sx={{
                                            ...allpageFontStyle.IconFontSize,
                                            // mt: "0.5rem",
                                          }}
                                          onClick={() => {
                                            handelremovetreeData(va, i, e);
                                          }}
                                        />
                                      </Box>
                                      {/* </Box> */}
                                    </Box>
                                  }
                                />
                              ))}
                            </TreeItem>
                          </>
                        ) : null}
                      </Box>
                    ))}
                </TreeView>
              </Box>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{}}>
            {/* <CustomCheckBox label={"Download"} onChange={handelDownloadCheckbox} color={'success'} disabled={!treeList.length > 0} checkedState={downloadState} /> */}
          </Box>
          {allowedPages().WorkStation.taskAssignment.assign && (
            <Button
              size="small"
              sx={{
                m: 2,
                color: "white",
                backgroundColor: "#258d4d",
                ...allpageFontStyle.buttonFontStyle2,
                borderRadius: 5,
                ":hover": {
                  boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px",
                },
              }}
              variant="contained"
              disabled={!treeList.length > 0}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          )}
          <Button
            size="small"
            color="error"
            onClick={() => {
              setAlertPopup((per) => ({
                ...per,
                open: true,
                type: PopupMessage.resetType,
                title: PopupMessage.reset,
              }));
            }}
            sx={{
              m: 2,
              color: "white",
              borderRadius: 5,
              ...allpageFontStyle.buttonFontStyle2,
              ":hover": { boxShadow: "rgba(0, 0, 0, 0.5) 0px 25px 50px -12px" },
            }}
            variant="contained"
          >
            {RESET_LABEL}
          </Button>
        </Box>
        <BootstrapDialog
          fullWidth
          maxWidth={"md"}
          sx={{ width: "100%", height: "100%", overflowY: "auto" }}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogContent>
            <Box sx={{}}>
              <h4 style={{ paddingLeft: 10 }}>Task Details</h4>
              <Grid container spacing={1} sx={{ pl: 10 }}>
                <Grid item xs={12} md={12} lg={6} sx={{ display: "flex" }}>
                  <Box sx={projectDetails}>Task ID :</Box>
                  <Box sx={projectContent}>{data.taskId}</Box>
                </Grid>

                <Grid item xs={12} md={12} lg={6} sx={{ display: "flex" }}>
                  <Box sx={projectDetails}>Job Code :</Box>
                  <Box sx={projectContent}>{data.jobCode}</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ display: "flex" }}>
                  <Box sx={projectDetails}>Task Type :</Box>
                  <Box sx={projectContent}>{data.taskType}</Box>
                </Grid>
                <Grid item xs={12} md={12} lg={6} sx={{ display: "flex" }}>
                  <Box sx={projectDetails}>Work Hours :</Box>
                  <Box sx={projectContent}>
                    {data.workHours} HH/{data.workMinutes} MM
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <h4 style={{ paddingLeft: 10 }}>Task Info</h4>

            <h6>
              <Grid container spacing={1} sx={{ pl: 10 }}>
                {keyValue &&
                  keyValue.length > 0 &&
                  keyValue.map((keys, i) => (
                    <Grid
                      sx={{ display: "flex", pl: 2 }}
                      xs={12}
                      md={12}
                      lg={6}
                    >
                      <Box
                        sx={{
                          fontFamily: "lato",
                          fontWeight: "bold",
                          fontSize: "14px",
                          pl: 1,
                          textTransform: "capitalize",
                        }}
                      >
                        {keys}:
                      </Box>
                      <Box sx={projectContent}>{data?.taskInfo?.[keys]}</Box>
                    </Grid>
                  ))}
              </Grid>
            </h6>
          </DialogContent>
        </BootstrapDialog>
        <CustomDialog
          open={downloadState}
          // onClose={handleSubmitClosepopup}
          actionStyle={{ display: "flex", justifyContent: "center" }}
          actions={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={{ pr: 2 }}>
                <CustomButton
                  buttonType={"Button"}
                  variant={"contained"}
                  onClick={() => submitOnclick("yes")}
                  label="Yes"
                />
              </Box>
              <Box>
                <CustomButton
                  buttonType={"Button"}
                  color={"error"}
                  variant={"contained"}
                  onClick={() => submitOnclick()}
                  label="No"
                />
              </Box>
            </Box>
          }
          content={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
              >
                <Box sx={{ textAlign: "center" }}>{"Task Assgined"}</Box>
                <Box sx={{ textAlign: "center" }}>
                  {"Do you want the assigned data in an excel ?"}
                </Box>
              </Box>
            </Box>
          }
        />
        <CustomDialog
          open={reassignPopup?.state}
          onClose={() => submitOnclick("NoReassign")}
          maxWidth={
            reassignPopup?.reassignEmployeeList &&
            typeof reassignPopup?.reassignEmployeeList !== "string"
              ? "md"
              : "xs"
          }
          actionStyle={{ display: "flex", justifyContent: "center" }}
          actions={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {reassignPopup?.reassignEmployeeList &&
                typeof reassignPopup?.reassignEmployeeList !== "string" && (
                  <Box sx={{ pr: 2 }}>
                    <CustomButton
                      buttonType={"Button"}
                      variant={"contained"}
                      onClick={() => submitOnclick("Reassign")}
                      label="Reassign"
                    />
                  </Box>
                )}

              <Box>
                <CustomButton
                  buttonType={"Button"}
                  color={"error"}
                  variant={"contained"}
                  onClick={() => submitOnclick("NoReassign")}
                  label={
                    reassignPopup?.reassignEmployeeList &&
                    typeof reassignPopup?.reassignEmployeeList !== "string"
                      ? "No"
                      : "Okay"
                  }
                />
              </Box>
            </Box>
          }
          content={
            <Box>
              <Box
                sx={{
                  fontFamily: "Lato",
                  textAlign: "center",
                  p: 2,
                  fontWeight: "bold",
                }}
              >
                {reassignPopup?.reassignEmployeeList &&
                typeof reassignPopup?.reassignEmployeeList !== "string"
                  ? "The list of Job(s) already assigned is given below.  If sure, press REASSIGN button to reassign or NO to exit."
                  : null}
              </Box>
              {typeof reassignPopup?.reassignEmployeeList !== "string" ? (
                <Box sx={{}}>
                  <DataGride
                    columns={reassignPopup.columns}
                    customHeight={450}
                    hideDeletion={true}
                    disableCheckbox={true}
                    getRowId={(row) => row?.taskId}
                    tableData={reassignPopup?.reassignEmployeeList || []}
                  />
                </Box>
              ) : (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontFamily: "Lato", padding: "10px 2px" }}>
                    {" "}
                    Invalid details given below !<br />
                    Please make necessary changes and try again
                  </span>
                  <Box sx={{}}>
                    <DataGride
                      columns={assigneErrorColumns}
                      customHeight={450}
                      hideDeletion={true}
                      disableCheckbox={true}
                      // getRowId={(row) => row.taskId}
                      tableData={reassignPopup?.reassignErrorColumeList || []}
                    />
                  </Box>
                  {/* <Typography sx={{ fontFamily: "Lato", pt: 2 }}>
                    {"(" + reassignPopup?.reassignEmployeeList + ")"}
                  </Typography> */}
                </Box>
              )}
            </Box>
          }
        />

        <CustomDialog
          open={loading}
          onClose={handleClosepopup}
          content={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
              >
                <Box>{"Data is processing "}</Box>
                <Box sx={{ textAlign: "center" }}>{"please wait"}</Box>{" "}
              </Box>
              <Box sx={{ px: 2 }}>
                <ProgressBar />
              </Box>
            </Box>
          }
        />
        <CustomDialog
          open={checkedState?.popupstate}
          onClose={handelClosePopupMessage}
          content={
            <Box Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box>{checkedState.popupmessage}</Box>
            </Box>
          }
        />
        <CustomDialog
          open={loading1}
          onClose={handleClosepopup1}
          content={
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{ display: "flex", flexDirection: "column", fontSize: 15 }}
              >
                <Box>{"Assigning in progress"}</Box>
                <Box sx={{ textAlign: "center" }}>{"please wait"}</Box>{" "}
              </Box>
              <Box sx={{ px: 2 }}>
                <ProgressBar />
              </Box>
            </Box>
          }
        />
      </Card>
      <PopupComponent
        open={alertPopup.open}
        handleResult={handelResult}
        handelClose={handelCancel}
        title={alertPopup.title}
      />
    </Box>
  );
}
