import { Circle, CircleOutlined, Info } from "@mui/icons-material";
import { Box, Card, CardActions, CardHeader, Popover, Tooltip } from "@mui/material";

export const imageImports = {
    excelDownload: require("../pics/exceldownload.png"),
    download: require("../pics/download.png"),
    download1: require("../pics/download1.png"),
    download2: require("../pics/download2.png"),
    download3: require("../pics/download3.png"),
    download4: require("../pics/download4.png"),
    excelUpload: require("../pics/excelupload.png"),
    upload: require("../pics/upload.png"),
    upload1: require("../pics/upload1.png"),
    defaultProfileImage: require("../images/login/user.png")
};
export const ImageTag = (props) => {
    const { src, alt, heigth, width, style, ...extra } = props
    return <img src={src} height={heigth || 25} width={width || ""} style={{ ...style }} alt={alt} {...extra} />;
};
export const DashboardCard = (props) => {
    const { cardStyle, cardheader, containerStyle, content, contentStyle, onclick, cardFooter } = props
    return (
        <Box sx={{ ...containerStyle, }}>
            <Card sx={{ ...cardStyle, pl: 0, py: 0, borderRadius: 3, boxShadow: 'none' }} onClick={onclick}>
                {cardheader && <CardHeader >{cardheader}</CardHeader>}
                <Box sx={{ ...contentStyle, py: 0 }}>
                    {content}
                </Box>
                {cardFooter && <CardActions >{cardFooter}</CardActions>}
            </Card>
        </Box>
    )
}
export const passwordChecking = (data, condition, minLength) => {

    if (minLength ? data.length > minLength : data.match(condition)) {
        return <Circle sx={{ color: '#3be01a', fontSize: 13 }} />
    } else {
        return < CircleOutlined sx={{ fontSize: 13 }} color="error" />
    }
}
export const passwordCheckingState = (data, condition, minLength) => {

    if (minLength ? data.length > minLength : data.match(condition)) {
        return "#3be01a"
    } else {
        return "red"
    }
}
export const CustomDropDown = (props) => {
    const { open, anchorEl, setAnchorEl, Content, tooltip } = props
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (<Box>

        <Tooltip title={tooltip || "Password Rule"}>
            <Info id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}

                onClick={handleClick} sx={{ fontSize: 15, color: 'blue', cursor: 'pointer' }} />

        </Tooltip>
        {/* </IconButton> */}
        <Popover
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            sx={{ width: "80%" }}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}
        >


            <Box sx={{ p: 1 }}> {Content}</Box>

        </Popover>
    </Box>)
}